const LOAD_MERMA_GLOBAL = 'LOAD_MERMA_GLOBAL';

export function loadMermaFromDateRange(start_date, end_date, id_superficie) {
  return {
    type: LOAD_MERMA_GLOBAL,
    payload: {
      request: {
        url: `/v1/merma/defectos/`,
        params: {
            start_date, end_date, finca_id: id_superficie
        }
      }
    }
  };
}
