export const SET_EMPTY_FINCAS = 'SET_EMPTY_FINCAS';

const initialState = [];

export const setEmptyFincas = fincas => ({
  type: SET_EMPTY_FINCAS,
  payload: fincas
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPTY_FINCAS: {
      return [...action.payload];
    }

    default:
      return state;
  }
};

export default reducer;
