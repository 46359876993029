export const SET_MARCAS = 'SET_MARCAS';

const initialState = [];

export const setMarcas = marcas => ({
  type: SET_MARCAS,
  payload: marcas
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MARCAS: {
      return [...action.payload];
    }

    default:
      return state;
  }
};

export default reducer;
