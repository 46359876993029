import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { Provider as AlertProvider, positions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Route, Switch, Redirect } from "react-router"; // react-router v4
import { ConnectedRouter } from "connected-react-router";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withRouter } from "react-router";
import configureStore, { history } from "./redux/configureStore";
import BonificacionDashboard from "./components/tthh/bonificacion/BonificacionDashboard";
import { LoaderContextProvider } from "./components/LoaderContext";

import Header from "./components/Header";
import SideBar from "./containers/SideBar";
import NotAllowed from "./components/notallowed";

import "material-icons/iconfont/material-icons.css";

import { getSubdomain } from "./helpers/agroiqConfig";

import ComponentLoader from "./components/ComponentLoader";
import Start from "./components/start/start";
import IndicadoresFinancieros from "./components/ginafruit/indicadoresFinancieros";
import GinaQuality from "./components/ginafruit/ginaQuality";
import PrivateRoute from "./utils/privateroute";

const MermaDashboard = React.lazy(() => import("./components/MermaDashboard"));
const MermaDiaDashboard = React.lazy(() =>
  import("./containers/MermaDiaDashboard")
);
const CalidadDiaDashboard = React.lazy(() =>
  import("./containers/calidad/CalidadDiaDashboard")
);
const CalidadComparativoDashboard = React.lazy(() =>
  import("./containers/calidad/CalidadComparativoDashboard")
);
const CalidadTendenciaDashboard = React.lazy(() =>
  import("./containers/calidad/CalidadTendenciaDashboard")
);
const LaboresReporteDiarioDashboard = React.lazy(() =>
  import("./containers/labores/LaboresReporteDiarioDashboard")
);
const LaboresReporteComparativoDashboard = React.lazy(() =>
  import("./containers/labores/LaboresReporteComparativoDashboard")
);
const LaboresReporteTendenciaDashboard = React.lazy(() =>
  import("./containers/labores/LaboresReporteTendenciaDashboard")
);
const ProduccionCajasDashboard = React.lazy(() =>
  import("./containers/produccion/ProduccionCajasDashboard")
);

const development = "development"; // DEFAULT ENV

const theme = createMuiTheme({
  typography: {
    htmlFontSize: 10,
    useNextVariants: true,
  },
});

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

const initialState = {};

const store = configureStore(initialState);

const isGinaFruit = ["ginafruit", "agroiq-ginafruit"].includes(getSubdomain());
const isSumifru = ["sumifru", "agroiq-sumifru"].includes(getSubdomain());

const AnimatedRoutes = () => {};

const App = (props) => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <ConnectedRouter history={history}>
          <div>
            <Route exact extrict path="/" component={Start} />
            <Route
              exact
              path="/dashboard/profile"
              render={() => (
                <div className="content-wrapper">
                  <section className="content-header">
                    <h3> NO EXISTE ESTA VISTA </h3>
                  </section>
                </div>
              )}
            />
            <Route path="/dashboard" component={Header} />
            <Route path="/" component={SideBar} />
            <Switch>
              <Route exact path="/notallowed" component={NotAllowed} />
              <Route exact path="/dashboard/labores">
                <Redirect to="/dashboard/labores/diario" />
              </Route>
              {!isGinaFruit && (
                <PrivateRoute
                  path="/dashboard/merma/general"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <MermaDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {!isGinaFruit && (
                <PrivateRoute
                  path="/dashboard/merma/diario"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <MermaDiaDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {!isGinaFruit && (
                <PrivateRoute
                  path="/dashboard/labores/diario"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <LaboresReporteDiarioDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {!isGinaFruit && (
                <PrivateRoute
                  path="/dashboard/labores/comparativo"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <LaboresReporteComparativoDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {!isGinaFruit && (
                <PrivateRoute
                  path="/dashboard/produccion/cajas"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <ProduccionCajasDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {!isGinaFruit && (
                <PrivateRoute
                  path="/dashboard/labores/tendencia"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <LaboresReporteTendenciaDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {(isGinaFruit || isSumifru) && (
                <PrivateRoute
                  path="/dashboard/calidad/dia"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <CalidadDiaDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {(isGinaFruit || isSumifru) && (
                <PrivateRoute
                  path="/dashboard/calidad/comparativo"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <CalidadComparativoDashboard {...props} />
                    </Suspense>
                  )}
                />
              )}
              {(isGinaFruit || isSumifru) && (
                <PrivateRoute
                  path="/dashboard/calidad/tendencia"
                  component={(props) => (
                    <Suspense fallback={<ComponentLoader />}>
                      <LoaderContextProvider>
                        <CalidadTendenciaDashboard {...props} />
                      </LoaderContextProvider>
                    </Suspense>
                  )}
                />
              )}
              {!isGinaFruit && (
                <PrivateRoute
                  path="/dashboard/tthh/bonificacion"
                  component={BonificacionDashboard}
                />
              )}
              {process.env.NODE_ENV === "production" && <Redirect to="/" />}
              {process.env.NODE_ENV === development && <Redirect to="/" />}
            </Switch>
          </div>
        </ConnectedRouter>
      </AlertProvider>
    </Provider>
  </MuiThemeProvider>
);

export default App;
