const LOGIN_SUBMIT = 'LOGIN_SUBMIT';

export function login(value) {
  return {
    type: LOGIN_SUBMIT,
    value
  };
}

const initialState = { username: null, password: null };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUBMIT:
      return Object.assign(
        {},
        state,
        action.value
      );

    default:
      return state;
  }
}
