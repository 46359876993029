import React, { useState } from 'react';

const LoaderContext = React.createContext([{}, () => {}]);

const LoaderContextProvider = (props) => {
  const [state, setState] = useState({
    loading: true,
  });

  const { children } = props;
  return (
    <LoaderContext.Provider value={[state, setState]}>
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderContext, LoaderContextProvider };
