import React, { useState, useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';

const NotAllowed = () => {
  useEffect(() => {
    window.location = "http://suspendido.procesosiq.com/"
  }, [])
  return (
  <div>
    Suspendido
  </div>
);
}

export default React.memo(NotAllowed);
