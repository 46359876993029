import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import es from 'react-date-range/dist/locale';
import { Calendar } from 'react-date-range';

import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  createStyles, Theme, withStyles
} from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import green from '@material-ui/core/colors/green';

import { HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION } from 'react-dates/constants';
import DateHelper from '../../helpers/global/dateHelper';
import { OdooAPI } from '../../api/rest-api';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const smallDevice = window.matchMedia('(max-width: 400px)').matches;
const orientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  labelRoot: {
    fontSize: 14
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  resize: {
    fontSize: 40
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  icon: {
    color: green[800],
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
});

const Bar = (props) => {
  const {
    classes,
    /* Se carga el resumen desde el bar, dependiendo de la fecha cambiada. */
    setRefreshData,
    setIndicadores,
  } = props;

  const [anchorEl, setAnchor] = useState(null);
  const [fecha, setFecha] = useState(new Date());

  const handleOpenCalendar = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchor(null);
  };

  const fetchData = () => {
    if (!setIndicadores) return;
    OdooAPI.getInvoiceIndicadores({
      fecha
    }).then((payload) => {
      setIndicadores(payload.data);
      setRefreshData(false);
    }).catch((err) => {
      console.error(err);
      setRefreshData(false);
    });
  };


  useEffect(() => {
    if (!setIndicadores) return;
    setRefreshData(true);
    fetchData();
  }, [fecha]);

  const handleDateChange = (evt) => {
    const fechaNew = DateHelper.getDateFromString(evt, 'YYYY-MM-DD');
    setFecha(fechaNew);
  };

  const open = Boolean(anchorEl);

  let reporteValorJSX = (<></>);
  reporteValorJSX = (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        style={{ float: 'right' }}
        aria-owns={open ? 'simple-popper' : undefined}
        onClick={handleOpenCalendar}
      >
        <TextField
          id="outlined-read-only-input"
          label="Fecha"
          value={DateHelper.getDateFromString(fecha, 'DD - MM, YYYY')}
          className={classes.textField}
          margin="normal"
          InputProps={{
            readOnly: true,
            style: { fontSize: 15 }
          }}
          InputLabelProps={{
            formlabelclasses: {
              root: classes.labelRoot
            }
          }}
          variant="outlined"
        />
      </div>

      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >

        <Calendar
          locale={es}
          date={DateHelper.getDateFromString(fecha) || new Date()}
          orientation={orientation}
          withPortal={smallDevice}
          onChange={handleDateChange}
        />
      </Popover>
    </>
  );


  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" className={classes.root}>
        <Toolbar>
          <div className={classes.grow} />
          <div style={{
            display: 'inline-flex', float: 'right', lineHeight: '200%', paddingRight: '5px'
          }}
          />

          <div style={{
            display: 'inline-flex', float: 'right', lineHeight: '200%', paddingRight: '5px'
          }}
          >
            <div style={{ alignSelf: 'center', paddingRight: '5px' }} />
          </div>
          <div>
            {reporteValorJSX}
          </div>
        </Toolbar>
      </AppBar>
    </div>

  );
};

Bar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  setRefreshData: PropTypes.func.isRequired,
  setIndicadores: PropTypes.func.isRequired
};

export default React.memo(withStyles(styles)(Bar));
