import axios from 'axios';

const { host } = window.location;
const parts = host.split('.');
const subdomain = parts && parts[0] ? parts[0] : 'error';

const baseURL = process.env.NODE_ENV === 'production'
  ? `https://${subdomain}.procesosiq.com/api` : `http://${subdomain}.localhost:8000/api`;

const axiosClient = axios.create({ baseURL });

class ProduccionAPI {
  static getNiveles = () => axiosClient.get('/v1/produccion/nivel/')

  static getSuperficies = () => axiosClient.get('/v1/produccion/superficie/')
}

/* Merma */
class MermaAPI {
  static getFechas = () => axiosClient.get('/v1/merma/merma_fechas/')

  static getFincas = () => axiosClient.get('/v1/merma/fincas/')

  static getDefectos = ({ start_date, end_date, finca_id }) => axiosClient.get('/v1/merma/defectos/', { params: { start_date, end_date, finca_id } })

  static getMermaNeta = ({ start_date, end_date, finca_id }) => axiosClient.get('/v1/merma/mermaneta/', { params: { start_date, end_date, finca_id } })

  static getMermaNetaHistorica = ({ year }) => axiosClient.get('/v1/merma/mermaneta_historico/', { params: { year } })

  static getDefectosHistorico = ({ year, nivel }) => axiosClient.get('/v1/merma/defectos_historico/', { params: { year, nivel } })


  static getTalloHistorico = ({ year, nivel }) => axiosClient.get('/v1/merma/porcentajetallo_historico/', { params: { year, nivel } })

  static getIndicadores = ({ start_date, end_date, id_superficie }) => axiosClient.get('/v1/merma/indicadores_superficie/', { params: { start_date, end_date, id_superficie } })
  
  static getFotos = ({ start_date, end_date, id_superficie }) => axiosClient.get('/v1/merma/fotos/', { params: { start_date, end_date, id_superficie } })
}

class MermaReportesAPI {
    static lastMerma = () => axiosClient.get('/v1/merma/last_merma/')
      .then(response => response)
      .catch(error => error.response);

  static getEmptyFincas = ({ start_date, end_date }) => axiosClient.get('/v1/merma/disabled_fincas/', { params: { start_date, end_date } })
}

/* Merma Diario */
class MermaDiarioAPI {
  static getMermaDiario = data => axiosClient.get('/v1/merma/mermadiario/', { params: data })
    .then(response => response);

  static getMermaDiarioBar = data => axiosClient.get('/v1/merma/mermadiario/bar/', { params: data })
}

class SuperficiesAPI {
  static get = id => axiosClient.get(`/v1/produccion/superficie/${id}`);

  static getName = id => axiosClient.get(`/v1/produccion/superficie/${id}/name`);

  static getRaices = () => axiosClient.get('/v1/produccion/superficie/raices/');

  static getRaicesFromLabores = () => axiosClient.get('/v1/llaa/fincas/');

  static getRaicesFromMerma = () => axiosClient.get('/v1/merma/fincas/');

  static getRaicesFromCalidad = () => axiosClient.get('/v1/calidad/fincas/');
}

class CategoriasAPI {
  static getCategoriasMermaDiario = data => axiosClient.get('/v1/merma/mermadiario/categorias/',
    { params: data });

  static getCategoriasMerma = data => axiosClient.get('/v1/merma/categoria', { params: data })
}

class LaboresAPI {
  static getBar = data => axiosClient.get('/v1/llaa/diario/bar', { params: data });

  static getResumen = data => axiosClient.get('/v1/llaa/diario/resumen', { params: data });

  static getResumenComparativo = data => axiosClient.get('/v1/llaa/comparativo/resumen', { params: data });

  static getResumenTendencia = data => axiosClient.get('/v1/llaa/tendencia/resumen', { params: data });

  static getAnios = () => axiosClient.get('/v1/llaa/bar/anios');

  static getFechas = () => axiosClient.get('/v1/llaa/bar/fechas');
}

class CalidadAPI {
  static getFechas = data => axiosClient.get('/v1/calidad/bar/fechas/', { params: data });

  static getAnios = data => axiosClient.get('/v1/calidad/bar/anios', { params: data });

  static getMarcas = data => axiosClient.get('/v1/calidad/bar/marcas', { params: data });

  static getDefectos = data => axiosClient.get('/v1/calidad/defectos/', { params: data });

  static getDetallesCaja = data => axiosClient.get('/v1/calidad/detalle_cajas/', { params: data });

  static getDetallesCluster = data => axiosClient.get('/v1/calidad/detalle_cluster', { params: data });

  static getResumenTendencia = data => axiosClient.get('/v1/calidad/tendencia/resumen', { params: data }); /* !!! */

  static getIndicadoresData = data => axiosClient.get('/v1/calidad/indicadores_data/', { params: data }); /* !!! */

  static getIndicadores = data => axiosClient.get('/v1/calidad/indicador/', { params: data }); /* !!! */
}

class ConfiguracionAPI {
  static getUmbral = (data, id = '') => axiosClient.get(`/v1/configuracion/umbral/${id}`, { params: data });

  static updateUmbral = (data, id) => axiosClient.patch(`/v1/configuracion/umbral/${id}/`, { ...data });

  static post = data => axiosClient.post('/v1/configuracion/umbral/', { ...data });
}

class OdooAPI {
  static getInvoiceIndicadores = data => axiosClient.get('/v1/produccion/indicadores_financieros', { params: data });
}

class TTHHAPI {
  static getSemanas = data => axiosClient.get('/v1/merma/semanas/', { params: data });

  static getBonificacion = data => axiosClient.get('/v1/merma/bonificacion/', { params: data });

  static getBonificacionHistorico = data => axiosClient.get('/v1/merma/bonificacion_historico/', { params: data });

  static getCausasHistorico = data => axiosClient.get('/v1/merma/causas_historico/', { params: data });
}

class CajasAPI {
}

export default axiosClient;

export {
  baseURL,
  MermaAPI,
  MermaReportesAPI,
  MermaDiarioAPI,
  SuperficiesAPI,
  CategoriasAPI,
  LaboresAPI,
  ConfiguracionAPI,
  OdooAPI,
  TTHHAPI,
  CalidadAPI,
  CajasAPI,
  ProduccionAPI,
};
