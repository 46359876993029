import { loadMermaHistoricaFromDateRange } from '../../api/merma/merma_historico';
import { loadDefectosHistoricaFromFincaAndYear } from '../../api/merma/defectos_historico';

import DateHelper from '../../helpers/global/dateHelper';

import { setMermaHistorico } from './mermaHistorico';
import { setDefectosHistorico } from './defectosHistorico';
import { getSelectedFincaFromFincas } from '../../helpers/fincasHelpers';

export const RESET_ANIO_MERMA= 'RESET_ANIO_MERMA';
const SET_ANIO_MERMA_HISTORICO = 'SET_ANIO_MERMA_HISTORICO';
const SET_ANIO_DEFECTOS_HISTORICO = 'SET_ANIO_DEFECTOS_HISTORICO';

const initialState = {
  merma_historica: DateHelper.getCurrentYear(),
  defectos_historico: DateHelper.getCurrentYear(),
};

const setAnioMerma = anio => async (dispatch, getState) => {
  dispatch(loadMermaHistoricaFromDateRange(anio, null, null, null)).then((response) => {
    dispatch(setMermaHistorico(response.payload.data));
  }).catch((e) => {
    console.error(e);
  });
  dispatch({
    type: SET_ANIO_MERMA_HISTORICO,
    payload: { merma_historica: anio }
  });
};

const setAnioDefectoHistorico = anio => async (dispatch, getState) => {
  const { fincas } = getState();

  const fincaSelected = getSelectedFincaFromFincas(fincas.datos);
  dispatch(loadDefectosHistoricaFromFincaAndYear(anio || DateHelper.getCurrentYear(), fincaSelected.id)).then((response) => {
    dispatch(setDefectosHistorico(response.payload.data));
  }).catch((e) => {
    console.error(e);
  });

  dispatch({
    type: SET_ANIO_DEFECTOS_HISTORICO,
    payload: { defectos_historico: anio }
  });
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ANIO_MERMA: {
      return { ...initialState };
    }
    case SET_ANIO_MERMA_HISTORICO: {
      return { ...state, ...action.payload };
    }

    case SET_ANIO_DEFECTOS_HISTORICO: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
}

export { setAnioMerma, setAnioDefectoHistorico };
