import { SUCCESS, LOADING } from '../../helpers/statusStates';
import { TABLA } from '../../helpers/mermaGlobalTypes';
import DateHelper from '../../helpers/global/dateHelper';
import { getLastDate } from '../../helpers/mermaFechasHelpers';

import { loadIndicadores } from '../../api/merma/indicadores';
import { loadMermaFechas } from '../../api/merma/merma_fechas';
import { loadDefectosHistoricaFromFincaAndYear } from '../../api/merma/defectos_historico';
import { setIndicadores } from './indicadores';
import { setMermaGlobal } from './merma';
import { loadMermaFromDateRange } from '../../api/merma/merma_global';

import { loadMermaHoy } from '../../api/merma/merma_hoy';
import { setMermaHoy } from './mermaHoy';
import { setDefectosHistorico } from './defectosHistorico';
import { setFechas } from './fechas';

import { parseResponses } from '../../helpers/indicadorHelper';
import { getSelectedFincaFromFincas } from '../../helpers/fincasHelpers';

export const SET_FINCAS = 'SET_FINCAS';
export const RESET_FINCAS = 'RESET_FINCAS';
export const SET_FINCAS_WIHOUT_DEFAULT_SELECT = 'SET_FINCAS_WIHOUT_DEFAULT_SELECT';
const SELECT_FINCA = 'SELECT_FINCA';

const initialState = { estado: LOADING, datos: [] };
const FETCH_FINCAS = 'FETCH_FINCAS';
const STARTER_PAGE = 1;

export function fetchFincas() {
  return {
    type: FETCH_FINCAS,
  };
}

export const justSelectFinca = fincaId => (dispatch) => {
  dispatch({
    type: SELECT_FINCA,
    payload: {
      finca: fincaId
    }
  });
};

export const selectFinca = (fincaId, callbacks) => (dispatch, getState) => {
  const { rangoFechas, anio } = getState();
  dispatch({
    type: SELECT_FINCA,
    payload: {
      finca: fincaId
    }
  });

  const fechas = DateHelper.getMomentFromRangoFechas(rangoFechas);

  if (callbacks) callbacks[1](true);
  dispatch(loadMermaFromDateRange(fechas.inicio, fechas.final, fincaId)).then((response) => {
    dispatch(setMermaGlobal(TABLA, response.payload.data));
    if (callbacks) callbacks[1](false);
  }).catch((e) => {
    console.error(e);
  });

  dispatch(
    loadDefectosHistoricaFromFincaAndYear(anio.defectos_historico, fincaId)
  ).then((response) => {
    dispatch(setDefectosHistorico(response.payload.data));
  }).catch((e) => {
    console.error(e);
  });

  const { count: mermaFechasCount } = fechas;
  dispatch(loadMermaFechas(null, mermaFechasCount, STARTER_PAGE)).then((response) => {
    dispatch(setFechas(response.payload.data));
    const fechasData = response.payload.data;
    dispatch(setFechas(fechasData));
    const lastDate = getLastDate(fechasData);

    if (lastDate) {
      const hoy = {
        inicio: DateHelper.getDateFromString(lastDate),
        final: DateHelper.getDateFromString(lastDate)
      };

      const hoyFormatted = DateHelper.getMomentFromRangoFechas(hoy);
      const { inicio: startHoy, final: endHoy } = hoyFormatted;

      dispatch(
        loadMermaHoy(startHoy, endHoy, fincaId)
      ).then((responseMermaHoy) => {
        dispatch(setMermaHoy(responseMermaHoy.payload.data));
      }).catch((e) => {
        console.error(e);
      });
    } else {
      dispatch(setMermaHoy([]));
    }
  }).catch((e) => {
    console.error(e);
  });

  if (callbacks) callbacks[0](true);
  return dispatch(loadIndicadores('merma',
    fechas.inicio,
    fechas.final, fincaId)).then((response) => {
    const resultados = response.payload.data;
    const indicadores = parseResponses(resultados);
    dispatch(setIndicadores(indicadores));
    if (callbacks) callbacks[0](false);
  }).catch((e) => {
    console.error(e);
  });
};

export const setFincasNew = fincas => async (dispatch) => {
  await dispatch({
    type: SET_FINCAS,
    payload: {
      fincas
    }
  });
};

export const resetFincas = fincas => async (dispatch) => {
  await dispatch({
    type: SET_FINCAS,
    payload: {
      fincas
    }
  });
};


export const setFincas = fincas => async (dispatch, getState) => {
  const { rangoFechas, anio, fechas: mermaFechas } = getState();
  await dispatch({
    type: SET_FINCAS,
    payload: {
      fincas
    }
  });
  const fincaSelected = getSelectedFincaFromFincas(fincas);
  const fechas = DateHelper.getMomentFromRangoFechas(rangoFechas);
  const { inicio, final } = fechas;

  if (fincaSelected) {
    dispatch(loadMermaFromDateRange(inicio, final, fincaSelected.id)).then((response) => {
      dispatch(setMermaGlobal(TABLA, response.payload.data));
    }).catch((e) => {
      console.error(e);
    });

    dispatch(
      loadDefectosHistoricaFromFincaAndYear(anio.defectos_historico, fincaSelected.id)
    ).then((response) => {
      dispatch(setDefectosHistorico(response.payload.data));
    }).catch((e) => {
      console.error(e);
    });

    const { count: mermaFechasCount } = mermaFechas;
    dispatch(loadMermaFechas(null, mermaFechasCount, STARTER_PAGE)).then((response) => {
      const fechasdata = response.payload.data;
      dispatch(setFechas(fechasdata));
      const lastDate = getLastDate(fechasdata);

      if (lastDate) {
        const hoy = {
          inicio: DateHelper.getDateFromString(lastDate),
          final: DateHelper.getDateFromString(lastDate)
        };

        const hoyFormatted = DateHelper.getMomentFromRangoFechas(hoy);
        const { inicio: startHoy, final: endHoy } = hoyFormatted;

        dispatch(loadMermaHoy(startHoy, endHoy, fincaSelected.id)).then((responseMermaHoy) => {
          dispatch(setMermaHoy(responseMermaHoy.payload.data));
        }).catch((e) => {
          console.error(e);
        });
      }
    }).catch((e) => {
      console.error(e);
    });

    return dispatch(loadIndicadores('merma',
      inicio,
      final, fincaSelected.id)).then((response) => {
      const resultados = response.payload.data;
      const indicadores = parseResponses(resultados);
      dispatch(setIndicadores(indicadores));
    }).catch((e) => {
      console.error(e);
    });
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FINCAS: {
      return {
        ...initialState
      };
    }
    case SET_FINCAS: {
      const datos = [...action.payload.fincas];
      let isSelected = false;
      datos.forEach((dato, i, arr) => {
        if (arr[i].selected === true) {
          isSelected = true;
        } else arr[i].selected = false;
      });

      if (!isSelected && datos[0]) datos[0].selected = true;

      return {
        ...state,
        datos,
        estado: SUCCESS,
      };
    }

    case SET_FINCAS_WIHOUT_DEFAULT_SELECT: {
      const datos = [...action.payload.fincas];
      return {
        ...state,
        datos
      };
    }
    case SELECT_FINCA: {
      const datos = [...state.datos];
      datos.forEach((dato, i, arr) => {
        if (action.payload.finca === null) {
          arr[i].selected = false;
        } else if (dato.id.toString() === action.payload.finca.toString()) {
          arr[i].selected = true;
        } else {
          arr[i].selected = false;
        }
      });

      return {
        ...state,
        datos,
        estado: SUCCESS,
      };
    }
    default:
      return state;
  }
}
