export const actionTypes = {
  SetNiveles: '[Produccion] Niveles',
  SetSuperficies: '[Produccion] Superficies',
};

const initialState = {
  niveles: undefined,
  superficies: undefined
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetNiveles:
      return {
        ...state,
        niveles: action.payload
      };

    case actionTypes.SetSuperficies:
      return {
        ...state,
        superficies: action.payload
      };


    default:
      return state;
  }
};

export const actions = {
  setSuperficies: payload => ({ type: actionTypes.SetSuperficies, payload }),
  setNiveles: payload => ({ type: actionTypes.SetNiveles, payload }),
};
