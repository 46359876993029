export const SET_RESUMEN = 'SET_RESUMEN';
export const SET_RESUMEN_TENDENCIA = 'SET_RESUMEN_TENDENCIA';

const initialState = [];

export const setResumen = (valor, isTendencia) => ({
  type: !isTendencia ? SET_RESUMEN : SET_RESUMEN_TENDENCIA,
  payload: valor
});


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESUMEN: {
      return [...action.payload];
    }

    case SET_RESUMEN_TENDENCIA: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default reducer;
