import React from 'react';
import PropTypes from 'prop-types';

import TBodyRow from './TBodyRow';

const uuidv4 = require('uuid/v4');

const TBody = (props) => {
  const {
    data,
    cotizaciones,
    tipo,
    bonificacion
  } = props;

  const values = Object.values(data);
  const dataJSX = values.map((value) => {
    const {
      row, data: childData
    } = value;
    return (
      <TBodyRow
        cotizaciones={cotizaciones}
        key={uuidv4()}
        bonificacion={bonificacion}
        data={row}
        tipo={tipo}
      >
        {childData}
      </TBodyRow>
    );
  });

  return (
    <tbody>
      { dataJSX }
    </tbody>
  );
};

TBody.defaultProps = {
  bonificacion: ''
};

TBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      )
    })
  ).isRequired,
  tipo: PropTypes.number.isRequired,
  bonificacion: PropTypes.string
};

export default React.memo(TBody);
