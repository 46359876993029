import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import TreeViewMenu from './TreeViewMenu';

const uuidv4 = require('uuid/v4');

const TreeView = (props) => {
  const {
    opcion,
    name,
    deactivateSiblings,
  } = props;
  const { children: parentChilds, icon } = opcion;

  const onSelectTreeview = () => {
  };

  let children = (<> </>);
  if (Array.isArray(parentChilds)) {
    children = parentChilds.map((key, i) => {
      const childKey = Object.keys(parentChilds[i])[0];
      const childData = parentChilds[i][childKey];
      if (childData.children) {
        return (
          <TreeView
            ischild
            key={uuidv4()}
            opcion={childData}
            name={childKey}
            deactivateSiblings={deactivateSiblings}
          />
        );
      }

      return (
        <TreeViewMenu
          key={uuidv4()}
          opcion={parentChilds[i]}
          name={childKey}
          deactivateSiblings={deactivateSiblings(i)}
        />
      );
    });
  }
  let imagenJSX = (<> </>);

  if (icon) {
    imagenJSX = (<img src={`/icons/${icon}.svg`} alt="icon" width="20" height="18" />);
  }


  return (
    <>
      <li className="treeview">
        <a href="#" onClick={onSelectTreeview}>
          { imagenJSX }
          {' '}
          <span>{ name }</span>
          <span className="pull-right-container">
            <i className="fa fa-angle-left pull-right" />
          </span>
        </a>
        <ul className="treeview-menu">
          { children }
        </ul>
      </li>
    </>
  );
};

TreeView.propTypes = {
  opcion: PropTypes.shape({}),
  name: PropTypes.string,
  deactivateSiblings: PropTypes.func.isRequired,
};

TreeView.defaultProps = {
  name: '',
  opcion: [],
};

export default withRouter(TreeView);
