const UPDATE_STATUS = 'UPDATE_STATUS';

const initialState = { indicadores: 'loading' };


export function updateStatus(status) {
  return {
    type: UPDATE_STATUS,
    payload: {
      ...status
    }
  };
}


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_STATUS: {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}
