import React from 'react';
import PropTypes from 'prop-types';

const THeader = (props) => {
  const { data } = props;

  const dataJSX = data.map((item) => {
    if (typeof item === 'string') return (<th key={item}>{item}</th>);

    return (<th key={item.nombre}>{item.nombre}</th>);
  });

  return (
    <thead>
      <tr>
        { dataJSX }
      </tr>
    </thead>
  );
};

THeader.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
};

THeader.defaultProps = {
  data: [],
};


export default React.memo(THeader);
