import React, { useState } from 'react';

const ResumenLote = () => {
  const [pesoBool, setPesoBool] = useState(true);
  const togglePesoBruto = () => {
    setPesoBool(!pesoBool);
  };

  return (
    <div className="portlet box green-haze">
      <div className="portlet-title">
        <span className="caption">RESUMEN POR FINCA</span>
      </div>
      <div className="portlet-body">
        <div className="table-responsive" id="promedios_lotes">
          <table className="table table-striped table-bordered table-hover table-condensed">
            <thead>
              <tr>
                <th className="center-th" />
                <th className="center-th ng-scope" colSpan="3" ng-if="edades.length > 0">EDAD</th>
                <th className="center-th" colSpan="4">RACIMOS</th>
                <th className="center-th" colSpan="4">PROMEDIO</th>
              </tr>
              <tr>
                <th className="center-th">FINCA</th>
                <th className="center-th bg-blue bg-font-blue" ng-repeat="e in edades">11</th>
                <th className="center-th bg-green-haze bg-font-green-haze" ng-repeat="e in edades">12</th>
                <th className="center-th bg-yellow-lemon bg-font-yellow-lemon" ng-repeat="e in edades">13</th>
                <th className="center-th">COSE</th>
                <th className="center-th">PROC</th>
                <th className="center-th">RECU</th>
                <th className="center-th">
PESO PROM
                  <a
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={togglePesoBruto}
                  >
                    <small className="ng-binding">{ pesoBool ? '(Bruto)' : '(Neto)' }</small>
                  </a>
                </th>
                <th className="center-th">CALIB 2DA</th>
                <th className="center-th ng-hide" ng-show="enabled.calibre_ultima">CALIB ULT</th>
                <th className="center-th">MANOS</th>
                <th className="center-th" ng-show="enabled.dedos">DEDOS</th>
              </tr>
            </thead>
            <tbody>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">PP1</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">53</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td className="ng-binding">53</td>
                <td className="ng-binding">52</td>
                <td className="ng-binding">1</td>
                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-red-thunderbird bg-font-red-thunderbird">58.99</td>
                <td className="bg-green-jungle bg-font-green-jungle">43.94</td>
                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">7.27</td>


                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">PP2</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">112</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">1</td>
                <td className="ng-binding">113</td>
                <td className="ng-binding">112</td>
                <td className="ng-binding">1</td>
                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-green-jungle bg-font-green-jungle">69.08</td>
                <td className="bg-green-jungle bg-font-green-jungle">43.94</td>
                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">8.15</td>


                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">PP3</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">12</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td className="ng-binding">12</td>
                <td className="ng-binding">12</td>
                <td className="ng-binding">0</td>

                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-green-jungle bg-font-green-jungle">69.47</td>

                <td className="bg-green-jungle bg-font-green-jungle">44.00</td>
                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">7.75</td>

                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">Germania</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">1</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">80</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">19</td>
                <td className="ng-binding">100</td>
                <td className="ng-binding">97</td>
                <td className="ng-binding">3</td>
                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-green-jungle bg-font-green-jungle">68.73</td>


                <td className="bg-green-jungle bg-font-green-jungle">44.00</td>

                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">8.41</td>


                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">Nueva Era</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">12</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">195</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">91</td>
                <td className="ng-binding">298</td>
                <td className="ng-binding">295</td>
                <td className="ng-binding">3</td>

                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-red-thunderbird bg-font-red-thunderbird">62.71</td>

                <td className="bg-green-jungle bg-font-green-jungle">43.97</td>

                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">8.08</td>
                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">Cuadrilla El Oro</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">120</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">104</td>
                <td className="ng-binding">224</td>
                <td className="ng-binding">223</td>
                <td className="ng-binding">1</td>
                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-green-jungle bg-font-green-jungle">{pesoBool ? 64.82 : 63.00.toFixed(2) }</td>
                <td className="bg-green-jungle bg-font-green-jungle">43.96</td>
                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">8.29</td>
                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">Puentecita</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">20</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td className="ng-binding">20</td>
                <td className="ng-binding">20</td>
                <td className="ng-binding">0</td>
                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-red-thunderbird bg-font-red-thunderbird">57.21</td>
                <td className="bg-green-jungle bg-font-green-jungle">44.05</td>
                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">7.75</td>
                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
              <tr ng-repeat="row in resumen" className="ng-scope">
                <td className="align-inherit">la Isla</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td ng-repeat="e in edades" className="ng-binding ng-scope">0</td>
                <td className="ng-binding">0</td>
                <td className="ng-binding">0</td>
                <td className="ng-binding">0</td>
                <td ng-class="toggle_peso_prom_bruto  ? 'bg-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual)+' bg-font-'+colorIndicador1(row.peso_neto,tags.kg_prom_neto_anual) : 'bg-'+colorIndicador1(row.peso,tags.kg_prom)+' bg-font-'+colorIndicador1(row.peso,tags.kg_prom) " className="ng-binding bg-red-thunderbird bg-font-red-thunderbird">0</td>
                <td className="bg-green-jungle bg-font-green-jungle">0</td>
                <td ng-show="enabled.calibre_ultima" className="ng-binding ng-hide" />
                <td className="bg-red-thunderbird bg-font-red-thunderbird">0</td>
                <td ng-show="enabled.dedos" className="ng-binding" />
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>TOTAL</th>
                <th className="center-th ng-binding ng-scope" ng-repeat="e in edades">13</th>
                <th className="center-th ng-binding ng-scope" ng-repeat="e in edades">592</th>
                <th className="center-th ng-binding ng-scope" ng-repeat="e in edades">215</th>
                <th className="center-th ng-binding">820</th>
                <th className="center-th ng-binding">811</th>
                <th className="center-th ng-binding">9</th>
                <th className="center-th ng-binding">
                  { pesoBool ? 64.62 : 62.80.toFixed(2) }
                </th>
                <th className="center-th ng-binding">43.95</th>
                <th className="center-th ng-binding ng-hide" ng-show="enabled.calibre_ultima">0.00</th>
                <th className="center-th ng-binding">8.11</th>
                <th className="center-th ng-binding" ng-show="enabled.dedos">0.00</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

  );
};

export default React.memo(ResumenLote);
