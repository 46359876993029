const ESTADOS = {
  MUY_BUENO: 'MUY_BUENO',
  BUENO: 'BUENO',
  MALO: 'MALO',
  MUY_MALO: 'MUY_MALO',
};

const colores = {
  GREEN: 'green-jungle',
  YELLOW: 'yellow-soft',
  ORANGE: 'red-haze',
  RED: 'red-thunderbird',
};

const statusChecker = (indicador, rangos) => {
  let colorStatus = colores.GREEN;
  if (rangos.length === 0) return colorStatus;
  Object.entries(ESTADOS).forEach(([index, valor]) => {
    const rango = rangos.find(r => r.estado_rango === valor);

    if (rango && indicador.porcentaje <= rango.maxValue && indicador.porcentaje > rango.minValue) {
      switch (rango.estado_rango) {
        case ESTADOS.MUY_BUENO: {
          colorStatus = colores.GREEN;
          break;
        }
        case ESTADOS.BUENO: {
          colorStatus = colores.YELLOW;
          break;
        }
        case ESTADOS.MALO: {
          colorStatus = colores.ORANGE;
          break;
        }
        case ESTADOS.MUY_MALO: {
          colorStatus = colores.RED;
          break;
        }
        default: {
          colorStatus = colores.GREEN;
          break;
        }
      }
    }
  });

  return colorStatus;
};

/* Esperamos que debido a que el indicador es de tallo recibamos los arreglos de valores de pesos de tallos y peso de racimos
   Retornamos los indicadores recibidos con el formato:
    nombre: {
      nombre: 'Practicas agricolas',
      porcentaje: valor1,
      peso: valor2
    },
*/
const parseResponses = (responses) => {
  const indicadores = responses.map(indicadorData => ({
    nombre: indicadorData.nombre,
    porcentaje: indicadorData.porcentaje,
    peso: indicadorData.valor,
    rango: indicadorData.rango,
    status: statusChecker(indicadorData, indicadorData.rango)
  }));
  return indicadores;
};


export {
  parseResponses
};
