import { LOADING } from '../../helpers/statusStates';

import { updateStatus } from './status';

export const RESET_DEFECTOS_HISTORICO = 'RESET_DEFECTOS_HISTORICO';
const SET_DEFECTOS_HISTORICO = 'SET_DEFECTOS_HISTORICO';

/*
  Podemos tener acumulados varios defectos por
  cada grafica que tenemos dentro de nuestra aplicacion.

*/

const initialState = {};

export function setDefectosHistorico(defectos) {
  return {
    type: SET_DEFECTOS_HISTORICO,
    payload: { defectos }
  };
}

export const resetDefectosHistorico = () => (dispatch) => {
  dispatch({
    type: RESET_DEFECTOS_HISTORICO,
    payload: {}
  });

  dispatch(updateStatus({ mermaGlobalType: LOADING }));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEFECTOS_HISTORICO: {
      return { ...state, ...action.payload };
    }

    case RESET_DEFECTOS_HISTORICO: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
