import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { useCountUp } from 'react-countup';

const Indicador = (props) => {
  const {
    nombre,
    porcentaje,
    peso,
    unidad,
    status,
    classIcon,
    icon,
  } = props;
  const [forceRerender, setForceRerender] = React.useState(true);

  let classStatus = '';
  let bgClassStatus = '';
  const { countUp, reset, update } = useCountUp({
    duration: Math.random(),
    end: 10000,
    decimals: 2,
    onReset: () => {
      setForceRerender(!forceRerender);
      update(porcentaje || peso);
    },
  });

  useEffect(() => {
    reset();
  }, [peso, porcentaje]);


  if (nombre === null
     || nombre === ''
     || peso === null) {
    return (

      <ContentLoader
        height={140}
        speed={1}
        primaryColor="#333"
        secondaryColor="#999"
      >
        {/* Only SVG shapes */}
        <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>

    );
  } /* Aun no traemos los datos del indicador */

  classStatus = `font-${status}`;
  bgClassStatus = status;

  let databar = null;
  if (!porcentaje) {
    databar = peso && !Number.isNaN(peso) ? Number(peso).toFixed(2) : 0;

    if (!databar) {
      databar = peso || 0;
    }
  } else databar = porcentaje && countUp ? countUp : 0;

  return (
    <div className="dashboard-stat2 ">
      <div className="display">
        <div className="number">
          <h3 className={classStatus}>
            <span
              className="counter_tags"
              data-value={databar}
            >
              {Number.isNaN(databar) ? databar : Number(databar).toFixed(2)}
            </span>
            &nbsp;&nbsp;
            <small className={classStatus}>{peso ? (unidad || '') : '' }</small>
          </h3>
          <small>
            { nombre }
          </small>
        </div>
        { classIcon
        && (
        <div className="icon">
          <i className={`fa ${classIcon}`} />
        </div>
        )
      }

        { icon
        && (
        <div className="icon">
          <img src={icon} alt="" width="25" />
        </div>
        )
      }
      </div>
      <div className="progress-info">
        <div className="progress">
          <span style={{ width: `${Number.isNaN(databar) ? 1000 : databar}px` }} className={`progress-bar progress-bar-success ${bgClassStatus}`}>
            <span className="sr-only ">
              { countUp }
 progress
            </span>
          </span>
        </div>
        <div className="status">
          <div className="status-title">
            { !Number.isNaN(peso) ? '' : peso }
          </div>
          <div className="status-number">
            {' '}
            { !Number.isNaN(peso) ? Number(peso || porcentaje).toFixed(2) : '' }
            {' '}

            {' '}
          </div>
        </div>
      </div>
    </div>
  );
};

Indicador.defaultProps = {
  icon: null
};

Indicador.propTypes = {
  nombre: PropTypes.string.isRequired,
  porcentaje: PropTypes.number.isRequired,
  peso: PropTypes.number.isRequired,
  unidad: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  classIcon: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default React.memo(Indicador);
