import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import $ from 'jquery';
import {
  unloadSidebar
} from '../../helpers/ui/removal';
import Item from './item';
import { getSubdomain, getUsuarioDataFromConfig } from '../../helpers/agroiqConfig';
import { goToUrl } from '../../helpers/routing';

require('./start.css');

const Start = (props) => {
  const isGinaFruit = ['ginafruit', 'agroiq-ginafruit'].includes(getSubdomain());
  const isMoran = ['moran', 'agroiq-moran'].includes(getSubdomain());
  const isArregui = ['arregui', 'agroiq-arregui'].includes(getSubdomain());
  const { nombres, compania } = getUsuarioDataFromConfig();

  useEffect(() => {
    unloadSidebar();
  }, []);

  const optionsGina = (
    <>
      <Grid item xs={12} md={4}>
        <Item
          logo="/logos/ginafruit.jpg"
          name="Indices financieros"
          href="/dashboard/indicadoresFinancieros"
          active
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Item
          logo="/logos/ginafruitquality.png"
          name="GinaQuality"
          href="/dashboard/ginaquality"
          active
        />
      </Grid>
    </>
  );
  return (
    <>
      <header className="page-header">
        <nav className="navbar" role="navigation">
          <div className="container-fluid">
            <div className="havbar-header">
              <a
                id="index"
                className="navbar-brand"
                href="/"
                onClick={
                  (evt) => {
                    const { history } = props;
                    evt.preventDefault();
                    goToUrl(history, '/');
                  }
                }
              >
                <img src="/logos/logo.png" alt="Logo" />
              </a>
              <div className="top-menu">
                <ul className="nav navbar-nav pull-right">
                  <li className="dropdown dropdown-user dropdown-dark">
                    <a href="javascript:;" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                      <span className="username username-hide-on-mobile">
                        { compania }
                        {' '}
                      </span>
                      <img alt="" className="img-circle" />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-default">
                      <li>
                        <a href="phrapi/access/logout">
                          <i className="icon-key" />
                          {' '}
                          Log Out
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <Grid
        container
        spacing={8}
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={4}>
          <Item
            logo="/logos/sigat.png"
            name="Sigatoka"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Item
            logo="/logos/agroaudit.png"
            name="Labores Agrícolas"
            href="/dashboard/labores/diario"
            active={!isGinaFruit && !isMoran && !isArregui}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Item
            logo="/logos/merma.png"
            name="Merma"
            href="/dashboard/merma/general"
            active={!isGinaFruit}
          />

        </Grid>
        {isGinaFruit && (
        <Grid item xs={12} md={4}>
          <Item
            logo="/logos/calidad.png"
            name="Calidad"
            href="/dashboard/calidad/dia"
            active
          />
        </Grid>
        )}
        <Grid item xs={12} md={4}>
          <Item
            logo="/logos/produccion.png"
            name="Produccion"
            href="http://app.procesos-iq.com/"
            active={!isGinaFruit && !isMoran}
          />

        </Grid>
        <Grid item xs={12} md={4}>
          <Item
            logo="/logos/erp-iq.png"
            name="ERP"
            href="http://ginafruit.erpiq.ec/"
            active={isGinaFruit}
          />

        </Grid>
        {isGinaFruit && optionsGina}
      </Grid>
    </>
  );
};

Start.propTypes = {
  history: PropTypes.shape({}).isRequired
};

export default React.memo(withRouter(Start));
