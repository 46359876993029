import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import Grid from "@material-ui/core/Grid";
import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";
import ErrorBoundary from "../../errors/ErrorBoundary";

import {
  getNameFromRoute,
  TIPOS_BONIFICACION,
  getSubdomain,
} from "../../../helpers/agroiqConfig";
import BonificacionBar from "./bonificacionBar";

import THeader from "../../ui/THeader";
import TBody from "./semanal/TBody";
import DateHelper from "../../../helpers/global/dateHelper";
import to from "../../../helpers/promiseCatcher";

import { TTHHAPI } from "../../../api/rest-api";

import "../../labores/labores.css";

import { loadSidebar } from "../../../helpers/ui/removal";

const BonificacionDashboard = (props) => {
  const [loading, setLoading] = useState(false);
  const [bonificacion, setBonificacion] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const isMarun = ["marun", "agroiq-marun"].includes(getSubdomain());
  const isArregui = ["arregui", "agroiq-arregui"].includes(getSubdomain());
  const isOrodelti = ["orodelti", "agroiq-orodelti"].includes(getSubdomain());

  const [semanas, setSemanas] = useState([]);
  const [bar, setBar] = useState({
    anio: Number(DateHelper.getCurrentYear()),
    semana: null,
    finca: null,
    tipo: TIPOS_BONIFICACION.DANIO,
    categoria: null,
  });

  const { location } = props;

  const [dashboardName] = useState(getNameFromRoute(location.pathname));

  useEffect(() => {
    if (isMarun || isArregui || isOrodelti)
      window.location.href = "http://app.procesos-iq.com/dashboardbonificacion";
    document.title = "TTHH - Bonificación";
    loadSidebar();
  }, []);

  const getRowHeader = (data) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      timezone: "America/Guayaquil",
    };
    const fechas = {};
    Object.keys(data).forEach((key1) => {
      Object.keys(data[key1].data).forEach((key2) => {
        fechas[key2] = null;
      });
    });

    if (fechas) {
      const dateKeys = Object.keys(fechas);
      dateKeys.sort((dateA, dateB) => new Date(dateA) - new Date(dateB));

      return dateKeys.map((item) =>
        new Date(`${item}T00:00:00`).toLocaleDateString("es-EC", options)
      );
    }
    return [];
  };

  const getCotizacion = (cotizaciones, valor) => {
    const cotizacionFound = cotizaciones.reduce((totalAcum, itemCot) => {
      let total = totalAcum;
      const maximo = Number(itemCot.maximo);
      const minimo = Number(itemCot.minimo);

      if (Number(valor) >= Number(minimo) && Number(valor) <= Number(maximo))
        total = itemCot;

      return total;
    }, null);

    return cotizacionFound;
  };

  const getBodyRowsChild = (lote, dateKeys) => {
    const { data } = lote;

    let causas = [];
    const result = [];
    Object.keys(data).forEach((key1) => {
      // recorrido dias
      causas = [...causas, ...Object.keys(data[key1].data)];
    });
    causas = [...new Set(causas)];
    let child = [];
    causas.forEach((causa) => {
      // recorrido causas
      child = [causa];
      dateKeys.forEach((dia) => {
        // recorrido dias
        let valor = null;

        if (
          data[dia] &&
          Object.prototype.hasOwnProperty.call(data[dia].data, causa)
        ) {
          valor = Number(data[dia].data[causa]).toFixed(2);
        }
        child = [...child, valor];
      });
      result.push([...child, null, null, null]);
    });
    return result;
  };

  const getBodyRows = (data) => {
    let cotizacion = null;
    const categoriaSelected = categorias.find(
      (item) =>
        item.id.toString() === (bar.categoria && bar.categoria.toString())
    );

    const resultLotes = [];
    let fechas = Object.keys(data).flatMap((key) =>
      Object.keys(data[key].data)
    );

    fechas = [...new Set(fechas)];
    fechas.sort((dateA, dateB) => new Date(dateA) - new Date(dateB));

    Object.keys(data).forEach((key1) => {
      // recorrido lote
      const resultDias = [];

      fechas.forEach((key2) => {
        // recorrido dias
        let resultDia = 0;
        if (data[key1].data[key2]) {
          // recorrido causas // reduce tambien
          Object.keys(data[key1].data[key2].data).forEach((key3) => {
            resultDia += data[key1].data[key2].data[key3];
          });
        }
        if (resultDia === 0) resultDias.push("-");
        else {
          resultDias.push(resultDia.toFixed(2));
        }
      });
      const resultadosFiltered = resultDias.filter(
        (item) => item && item !== "-" && Number(item) !== 0
      );

      const totalResultDias = resultadosFiltered.reduce(
        (acum, item) =>
          item ? Number(Number(acum) + Number(item)).toFixed(2) : Number(acum),
        0
      );

      const promSem = Number(
        resultadosFiltered.length > 0
          ? totalResultDias / resultadosFiltered.length
          : 0
      ).toFixed(2);

      let usd = 0;
      if (categoriaSelected && categoriaSelected.bonificaciones.length > 0) {
        cotizacion = getCotizacion(
          categoriaSelected.bonificaciones,
          Number(promSem)
        );
        usd =
          (cotizacion && Number(cotizacion.valor).toFixed(2)) ||
          Number(0).toFixed(2);
      }

      const exceed = 0;
      const row = [
        (data[key1].superficie && data[key1].superficie.nombre) || key1,
        data[key1].enfundador,
        ...resultDias,
        promSem,
        exceed,
        usd,
      ];
      const data2 = [...getBodyRowsChild(data[key1], fechas)];
      resultLotes.push({
        row,
        data: data2,
      });
    });
    return { cotizacion, resultLotes };
  };

  const hasSuperficie = Object.entries(bonificacion).reduce(
    (acum, [, item]) => item.superficie,
    true
  );

  const header = [
    hasSuperficie ? "Lote" : "Garruchero",
    "Responsable",
    ...getRowHeader(bonificacion),
    "Prom Sem",
    "Exced",
    "USD",
  ];
  const { resultLotes: body, cotizacion } = getBodyRows(bonificacion);

  const semanasJSX = semanas.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));
  const anios = [2022, 2021, 2020, 2019];
  const aniosJSX = anios.map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  const onChangeSemana = async (semana) => {
    setLoading(true);
    const [err, bonResponse] = await to(
      TTHHAPI.getBonificacion({ ...bar, finca_id: bar.finca, semana })
    );
    setLoading(false);
    if (err) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Algo malo ocurrió!",
        footer: "<a href>Por favor háblenos del error!</a>",
      });
    } else {
      setBonificacion(bonResponse.data);
    }
  };
  const onChangeAnio = async (anio) => {
    setLoading(true);

    const [errSem, semResponse] = await to(
      TTHHAPI.getSemanas({
        ...bar,
        anio,
        finca_id: bar.finca,
      })
    );

    setSemanas(semResponse.data);
    const semanaId =
      semResponse && semResponse.data && semResponse.data.length > 0
        ? semResponse.data[0]
        : null;

    const [err, bonResponse] = await to(
      TTHHAPI.getBonificacion({
        ...bar,
        finca_id: bar.finca,
        anio,
        semana: semanaId,
      })
    );

    setBar({
      ...bar,
      semana: semanaId,
      anio,
    });
    setLoading(false);
    if (err) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Algo malo ocurrió!",
        footer: "<a href>Por favor háblenos del error!</a>",
      });
    } else {
      setBonificacion(bonResponse.data);
    }
  };

  const categoriaSelected = categorias.find(
    (item) => item.id.toString() === (bar.categoria && bar.categoria.toString())
  );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title">{dashboardName}</h3>
              </div>
              <div className="page-bar box-body">
                <ErrorBoundary>
                  <BonificacionBar
                    setBar={setBar}
                    bar={bar}
                    loading={loading}
                    setLoading={setLoading}
                    semanas={semanas}
                    setSemanas={setSemanas}
                    setBonificacion={setBonificacion}
                    categorias={categorias}
                    setCategorias={setCategorias}
                  />
                </ErrorBoundary>
              </div>
              <div className="box">
                <div className="box-header with-border box_header_custom">
                  <h3 className="box-title title">Bonificación</h3>

                  <div
                    style={{
                      display: "inline-flex",
                      float: "right",
                      lineHeight: "200%",
                      paddingRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "center",
                        paddingRight: "5px",
                        color: "#ffffff",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      Semana:
                    </div>
                    <div style={{ alignSelf: "center", paddingRight: "5px" }}>
                      <select
                        name="idSemana"
                        className="form-control select-agroiq"
                        style={{ margin: "2px", textTransform: "capitalize" }}
                        value={(bar.semana && bar.semana.toString()) || ""}
                        onChange={(evt) => {
                          setBar({
                            ...bar,
                            semana: Number(evt.target.value),
                          });

                          onChangeSemana(evt.target.value);
                        }}
                      >
                        {semanasJSX}
                      </select>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "inline-flex",
                      float: "right",
                      lineHeight: "200%",
                      paddingRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "center",
                        paddingRight: "5px",
                        color: "#ffffff",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      Año:
                    </div>

                    <div style={{ alignSelf: "center", paddingRight: "5px" }}>
                      <select
                        name="idAnio"
                        className="form-control select-agroiq"
                        style={{ margin: "2px", textTransform: "capitalize" }}
                        value={(bar.anio && bar.anio.toString()) || ""}
                        onChange={(evt) => {
                          setBar({
                            ...bar,
                            anio: Number(evt.target.value),
                          });

                          onChangeAnio(evt.target.value);
                        }}
                      >
                        {aniosJSX}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="page-bar box-body">
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <LoadingOverlay
                        active={loading}
                        spinner
                        text="Cargando..."
                      >
                        <div className="table-responsive table-condensed">
                          <table className="table" id="resumen">
                            <THeader data={header} />
                            <TBody
                              data={body || []}
                              cotizaciones={
                                categoriaSelected &&
                                categoriaSelected.bonificaciones
                              }
                              bonificacion={cotizacion}
                              tipo={bar.tipo}
                            />
                          </table>
                        </div>
                      </LoadingOverlay>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="box-footer">
                <div className="row">
                  <div className="col-md-12" />
                </div>
              </div>
            </div>
          </div>

          <div className="h-divider col-md-12 col-sm-12 col-xs-12" />
          <div className="col-md-6 col-sm-6 col-xs-12" />

          <div className="col-md-6 col-sm-6 col-xs-12" />

          <div className="col-md-12 col-sm-12 col-xs-12">
            {/* <ErrorBoundary>
                <Historico
                data={historico}
                />
                </ErrorBoundary> */}
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12" />
          <div className="col-md-6 col-sm-12 col-xs-12" />
          <div className="col-md-6 col-sm-12 col-xs-12" />
          <div className="col-md-12 col-sm-12 col-xs-12" />
          <div className="col-md-12 col-sm-12 col-xs-12" />
        </div>
      </section>
    </div>
  );
};

BonificacionDashboard.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default React.memo(BonificacionDashboard);
