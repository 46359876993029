import $ from 'jquery';

export const unloadSidebar = () => {
  $(document).ready(() => {
    $('div#root').css({ backgroundColor: 'white' });
    $('.main-sidebar').css({ display: 'none' });
  });
};


export const loadSidebar = () => {
  $(document).ready(() => {
    $('div#root').css({ backgroundColor: '#fff' });
    $('.main-sidebar').css({ display: 'block' });
  });
}

export const openTreeBasedOnTitle = (title) => {
  $(document).ready(() => {
    $('.sidebar-menu > li.treeview').each(function () {
      var menu = $(this);
      if (menu.find('a > span').first().text() === title) {
        menu.addClass('menu-open');
        menu.find('ul.treeview-menu').css({ display: 'block' });
      }
    });
  });
};