import { all } from "redux-saga/effects";
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';

import * as mermaGeneral from './ducks/mermaGeneral.ducks';
import * as filters from './ducks/filters.ducks';
import * as produccion from './ducks/produccion.ducks';
import user from './user';
import opciones from './opciones';
import fincas from './fincas';
import indicadores from './indicadores';
import rangoFechas from './rango_fechas';
import status from './status';
import mermaGlobal from './merma';
import mermaHistorico from './mermaHistorico';
import mermaHoy from './mermaHoy';
import anio from './anio';
import niveles from './niveles';
import superficies from './superficies';
import defectosHistorico from './defectosHistorico';
import talloHistorico from './tallo_historico';
import fechas from './fechas';
import thresholds from './thresholds';
import fecha from './fecha';
import categorias from './categorias';
import mermadiario from './mermadiario';
import filtros from './filtros';
import bar from './bar';
import resumen from './resumen';
import umbral from './umbral';
import emptyFincas from './emptyFincas';
import marcas from './marcas';
import anios from './anios';

export function* rootSaga() {
  yield all([
    mermaGeneral.saga(),
  ]);
}

export const createRootReducer = history => combineReducers({
  mermaGeneral: mermaGeneral.reducer,
  filters: filters.reducer,
  produccion: produccion.reducer,
  bar,
  notifications,
  router: connectRouter(history),
  user,
  opciones,
  fincas,
  indicadores,
  rangoFechas,
  status,
  mermaGlobal,
  mermaHistorico,
  mermaHoy,
  anio,
  superficies,
  defectosHistorico,
  niveles,
  talloHistorico,
  fechas,
  thresholds,
  fecha,
  categorias,
  mermadiario,
  filtros,
  resumen,
  umbral,
  emptyFincas,
  marcas,
  anios,
});
