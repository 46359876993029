const LOAD_INDICADORES = 'LOAD_INDICADORES';

export function loadIndicadores(tipo, start_date, end_date, id_superficie) {
  return {
    type: LOAD_INDICADORES,
    payload: {
      request: {
        url: '/v1/merma/indicadores_superficie/',
        params: {
          start_date, end_date, tipo, id_superficie
        }
      }
    }
  };
}
