import { connect } from 'react-redux';
import SideBar from '../components/SideBar';

import {
  setOpciones,
} from '../redux/modules/opciones';

function mapStateToProps(state) {
  return {
    opciones: state.opciones, /* Configuracion del sidebar */
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOpciones: value => dispatch(setOpciones(value)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar);
