const SET_UMBRALES = 'SET_UMBRALES';
const SET_UMBRAL = 'SET_UMBRAL';

const initialState = {
  merma: [],
  labores: [],
  calidad: [],
};

export const setUmbrales = (key, values) => ({
  type: SET_UMBRALES,
  payload: {
    [key]: [...values]
  }
});

export const setUmbral = (key, value) => ({
  type: SET_UMBRAL,
  payload: {
    value,
    key,
  }
});


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_UMBRALES: {
      return {
        ...state,
        ...action.payload
      };
    }

    case SET_UMBRAL: {
      const newState = { ...state };
      const index = newState[action.payload.key]
        .findIndex(item => item.nombre === action.payload.value.nombre);

      const dataUmbral = [...newState[action.payload.key]];
      dataUmbral.splice(index, 1, action.payload.value);
      newState[action.payload.key] = dataUmbral;
      return newState;
    }

    default:
      return state;
  }
}
