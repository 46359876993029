const SET_OPCIONES = 'SET_OPCIONES';

export function setOpciones(value) {
  return {
    type: SET_OPCIONES,
    value
  };
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_OPCIONES: {
      return action.value;
    }
    default:
      return state;
  }
}
