export default class FakeAuth {
  constructor() {
    this.invalid = ['ruforcorp'];
  }

  getAuth() {
    const { host } = window.location;
    const parts = host.split('.');
    const subdomain = parts && parts[0] ? parts[0] : 'error';

    const isInValid = this.invalid.find(item => subdomain.includes(item));

    return !isInValid;
  }
}
