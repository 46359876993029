const LOAD_DEFECTOS_HISTORICO = 'LOAD_DEFECTOS_HISTORICO';

export function loadDefectosHistoricaFromFincaAndYear(anio, finca_id) {
  return {
    type: LOAD_DEFECTOS_HISTORICO,
    payload: {
      request: {
        url: '/v1/merma/defectos_historico/',
        params: {
          year: anio,
          finca_id
        }
      }
    }
  };
}
