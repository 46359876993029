/*
  Obtenemos las fechas donde se ha realizado la toma de merma
*/
const LOAD_MERMA_FECHAS = 'LOAD_MERMA_FECHAS';

/* finca_id es Opcional */
export function loadMermaFechas(finca_id, count = 20, page = 1) {
  return {
    type: LOAD_MERMA_FECHAS,
    payload: {
      request: {
        url: '/v1/merma/merma_fechas/',
        params: {
          finca_id,
          count,
          page
        }
      }
    }
  };
}
