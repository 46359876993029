export const SET_FILTRO = 'SET_FILTRO';
export const SET_FILTRO_BY_NAME = 'SET_FILTRO_BY_NAME';
export const SET_FILTROS = 'SET_FILTROS';
export const SET_FILTRO_COMPARATIVO = 'SET_FILTRO_COMPARATIVO';
export const REMOVE_FILTRO = 'REMOVE_FILTRO';
export const REMOVE_FILTROS = 'REMOVE_FILTROS';

const initialState = {
  fecha_inicio: new Date(),
  fecha_fin: new Date(),
}; /* Se tienen multiples filtros */

export const removeFiltros = () => ({
  type: REMOVE_FILTROS
});

export const setFiltro = (key, valor) => ({
  type: SET_FILTRO,
  payload: {
    key,
    valor
  }
});

export const setFiltros = (obj) => ({
  type: SET_FILTROS,
  payload: { ...obj }
});

export const setFiltroByName = (key, valor) => ({
  type: SET_FILTRO_BY_NAME,
  payload: {
    [key]: valor
  }
});

export const setFiltroModuloComparativo = (inicio, fin) => ({
  type: SET_FILTRO,
  payload: {
    inicio, fin
  }
});


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTROS: {
      return {
        ...action.payload
      };
    }
    case SET_FILTRO: {
      return {
        ...action.payload
      };
    }

    case SET_FILTRO_BY_NAME: {
      return {
        ...state,
        ...action.payload
      };
    }

    case REMOVE_FILTROS: {
      return {};
    }
    default:
      return state;
  }
};

export default reducer;
