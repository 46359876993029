import { SUCCESS } from '../../helpers/statusStates';
import { TABLA } from '../../helpers/mermaGlobalTypes';
import { loadIndicadores } from '../../api/merma/indicadores';
import { loadMermaFromDateRange } from '../../api/merma/merma_global';
import { resetIndicadores, setIndicadores } from './indicadores';
import { setMermaGlobal } from './merma';
import { setEmptyFincas } from './emptyFincas';

import DateHelper from '../../helpers/global/dateHelper';
import { parseResponses } from '../../helpers/indicadorHelper';
import { getSelectedFincaFromFincas } from '../../helpers/fincasHelpers';

import {
  MermaReportesAPI
} from '../../api/rest-api';

const SET_RANGE = 'SET_RANGE';
export const RESET_RANGE = 'RESET_RANGE';

const initialState = {
  inicio: new Date(),
  final: new Date()
};

const setRango = rangoFechas => ({
  type: SET_RANGE,
  payload: {
    rangoFechas
  }
});

export const updateRangoAndUpdateData = ( rangofechas, callbacks ) => (dispatch, getState) => {
  const { fincas } = getState();

  const fincaSelected = getSelectedFincaFromFincas(fincas.datos);
  dispatch(setRango(rangofechas));
  dispatch(resetIndicadores());

  if (fincaSelected) {
    const rangoFechas = DateHelper.getMomentFromRangoFechas(rangofechas);
    const { inicio: fechaInicio, final: fechaFinal } = rangoFechas;

    if(callbacks) callbacks[1](true);
    dispatch(loadMermaFromDateRange(fechaInicio, fechaFinal, fincaSelected.id)).then((response) => {
      dispatch(setMermaGlobal(TABLA, response.payload.data));
      if(callbacks) callbacks[1](false);
    }).catch((e) => {
      console.error(e);
    });

    MermaReportesAPI.getEmptyFincas({
      start_date: fechaInicio,
      end_date: fechaFinal,
    }).then((response) => {
      dispatch(setEmptyFincas(response.data));
    }).catch((err) => {
      console.error(err);
      dispatch(setEmptyFincas([]));
    });

    if(callbacks) callbacks[0](true);
    return dispatch(loadIndicadores('merma',
      fechaInicio,
      fechaFinal, fincaSelected.id)).then((response) => {
      const resultados = response.payload.data;
      const indicadores = parseResponses(resultados);
      dispatch(setIndicadores(indicadores));
        if(callbacks) callbacks[0](false);
    }).catch((e) => {
      console.error(e);
    });
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_RANGE: {
      return {
        ...initialState,
      };
    }

    case SET_RANGE: {
      return {
        ...state,
        ...action.payload.rangoFechas,
        estado: SUCCESS,
      };
    }

    default:
      return state;
  }
}
