import { put, takeLatest } from 'redux-saga/effects';
import * as filterDucks from './filters.ducks';

export const actionTypes = {
  SetMermaHistorica: '[MermaGeneral] Historico',
  SetMermaLastDate: '[MermaGeneral] Merma LastDate',
  SetIndicadores: '[MermaGeneral] Indicadores',
  SetDefectosHistorico: '[MermaGeneral] Defectos Historico',
  SetTallosHistorico: '[MermaGeneral] Tallo Historico',
  SetDefectos: '[MermaGeneral] Defectos',

  SetYears: '[MermaGeneral] Years',
  SetFechas: '[MermaGeneral] Fechas',
  SetFincas: '[MermaGeneral] Fincas',
  SetDisabledFincas: '[MermaGeneral] Disabled Fincas',
  SetLoadDisabledFincas: '[MermaGeneral] Load Disabled Fincas',
  
  SetFotos: '[MermaGeneral] Load Fotos',
};

const initialState = {
  defectosHistorico: undefined,
  talloHistorico: undefined,
  mermaHistorico: undefined,
  mermaLastDate: undefined,
  defectos: undefined,
  indicadores: undefined,
  fincas: undefined,
  disabledFincas: undefined,
  fechas: undefined,
  years: undefined,
  fotos: undefined,
  threshold: 10,

  /*
     LOADING FLAG:
     We already have disabledFincas inside the state.
     We will add this flag so we can still have this right here and the ui feels smoother.
*/
  loadDisabledFincas: false,
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetMermaHistorica:
      return {
        ...state,
        mermaHistorico: { ...action.payload, }
      };

    case actionTypes.SetMermaLastDate:
      return {
        ...state,
        mermaLastDate: { ...action.payload, }
      };

    case actionTypes.SetIndicadores:
      return {
        ...state,
        indicadores: [...action.payload]
      };

    case actionTypes.SetDefectosHistorico:
      return {
        ...state,
        defectosHistorico: { ...action.payload }
      };

    case actionTypes.SetTallosHistorico:
      return {
        ...state,
        talloHistorico: { ...action.payload }
      };

    case actionTypes.SetDefectos:
      return {
        ...state,
        defectos: { ...action.payload }
      };

    case actionTypes.SetFechas: {
      return {
        ...state,
        fechas: [...action.payload],
      };
    }
    case actionTypes.SetYears: {
      return {
        ...state,
        years: [...action.payload],
      };
    }
    case actionTypes.SetFincas:
      return {
        ...state,
        fincas: [...action.payload]
      };

    case actionTypes.SetDisabledFincas:
      return {
        ...state,
        loadDisabledFincas: false,
        disabledFincas: [...action.payload]
      };

    case actionTypes.SetIndicadores:
      return {
        ...state,
        indicadores: [...action.payload]
      };

    case actionTypes.SetLoadDisabledFincas:
      return {
        ...state,
        loadDisabledFincas: true,
      };

    case actionTypes.SetFotos:
      return {
        ...state,
        fotos: action.payload,
      };

    default:
      return state;
  }
};

export const actions = {
  setIndicadores: payload => ({ type: actionTypes.SetIndicadores, payload }),
  setFincas: payload => ({ type: actionTypes.SetFincas, payload }),
  setDisabledFincas: payload => ({ type: actionTypes.SetDisabledFincas, payload }),
  setLoadDisabledFincas: () => ({ type: actionTypes.SetLoadDisabledFincas }),
  setFechas: payload => ({ type: actionTypes.SetFechas, payload }),
  setYears: payload => ({ type: actionTypes.SetYears, payload }),
  setMermaHistorica: payload => ({ type: actionTypes.SetMermaHistorica, payload }),
  setTalloHistorica: payload => ({ type: actionTypes.SetTallosHistorico, payload }),
  setDefectosHistorico: payload => ({ type: actionTypes.SetDefectosHistorico, payload }),
  setMermaLastDate: payload => ({ type: actionTypes.SetMermaLastDate, payload }),
  setDefectos: payload => ({ type: actionTypes.SetDefectos, payload }),
  setFotos: payload => ({ type: actionTypes.SetFotos, payload }),
};


export function* saga() {
  yield takeLatest(actionTypes.SetFechas, function* yearsSaga({ payload }) {
    const years = [...new Set(payload.map(item => item.split('-')[0]))];
    yield put(actions.setYears(years));

    /* If we already have a year defined we can set this year filter too */
    yield put(filterDucks.actions.setYear((years && years.length > 0 && years[0]) || undefined))
  });
}
