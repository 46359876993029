const SET_NIVELES = 'SET_NIVELES';
export const RESET_NIVELES = 'RESET_NIVELES';

const initialState = [];


export const setNiveles = niveles => async (dispatch) => {
  dispatch({
    type: SET_NIVELES,
    payload: {
      niveles
    }
  });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_NIVELES: {
      return [...initialState];
    }
    case SET_NIVELES: {
      return [...action.payload.niveles];
    }
    default:
      return state;
  }
}
