import React from 'react';
import { Route, Redirect } from 'react-router'; // react-router v4
import FakeAuth from '../auth/fakeauth';

const fakeAuth = new FakeAuth();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (fakeAuth.getAuth() ? (<Component {...props} />) : (
      <Redirect to="/notallowed" />))}
  />
);

export default PrivateRoute;
