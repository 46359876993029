import { SUCCESS, LOADING } from '../../helpers/statusStates';
import { updateStatus } from './status';

const SET_INDICADORES = 'SET_INDICADORES';
export const RESET_INDICADORES = 'RESET_INDICADORES';

const initialState = { };

function setIndicadoresData(indicadores) {
  return {
    type: SET_INDICADORES,
    payload: {
      indicadores
    }
  };
}

export const setIndicadores = indicadores => (dispatch) => {
  dispatch(updateStatus({ indicadores: SUCCESS }));
  dispatch(setIndicadoresData(indicadores));
};

export const resetIndicadores = () => (dispatch) => {
  dispatch({
    type: RESET_INDICADORES,
    payload: {}
  });

  dispatch(updateStatus({ indicadores: LOADING }));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INDICADORES: {
      return {
        ...state,
        ...action.payload.indicadores,
      };
    }
    case RESET_INDICADORES: {
      return initialState;
    }

    default:
      return state;
  }
}
