const SET_SUPERFICIES = 'SET_SUPERFICIES';

const initialState = [];

export const setSuperficies = superficies => async (dispatch) => {
  dispatch({
    type: SET_SUPERFICIES,
    payload: {
      superficies
    }
  });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SUPERFICIES: {
      return [...action.payload.superficies];
    }
    default:
      return state;
  }
}
