const SET_TALLO_HISTORICO = 'SET_TALLO_HISTORICO';
export const RESET_TALLO_HISTORICO = 'RESET_TALLO_HISTORICO';

const initialState = [];

export const setTalloHistorico = tallos => async (dispatch) => {
  dispatch({
    type: SET_TALLO_HISTORICO,
    payload: {
      tallos
    }
  });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_TALLO_HISTORICO: {
      return initialState;
    }
    case SET_TALLO_HISTORICO: {
      return [...action.payload.tallos];
    }
    default:
      return state;
  }
}
