const SET_ANIOS = 'SET_ANIOS';
const initialState = [];

export function setAnios(anios) {
  return {
    type: SET_ANIOS,
    payload: anios
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ANIOS: {
      return [...action.payload ];
    }

    default:
      return state;
  }
}
