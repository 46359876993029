const SET_FECHAS = 'SET_FECHAS';

const initialState = { fechas: [], count: 20, page: 1 };

export function setFechas(fechas) {
  return {
    type: SET_FECHAS,
    payload: fechas
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FECHAS: {
      return { ...state, fechas: action.payload };
    }

    default:
      return state;
  }
}
