export const SET_CATEGORIAS = 'SET_CATEGORIAS';
export const SELECT_CATEGORIA = 'SELECT_CATEGORIAS';

const initialState = [];

export const selectCategoria = id => ({
  type: SELECT_CATEGORIA,
  payload: {
    id
  }
});

export const setCategorias = categorias => ({
  type: SET_CATEGORIAS,
  payload: {
    categorias
  }
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_CATEGORIA: {
      const estado = [...state];

      estado.forEach((dato, i, arr) => {
        if (dato.id.toString() === action.payload.id.toString()) {
          arr[i].selected = true;
        } else {
          arr[i].selected = false;
        }
      });

      return estado;
    }
    case SET_CATEGORIAS: {
      const estado = [...action.payload.categorias];
      if (estado.length > 0) estado[0].selected = true;
      return estado;
    }

    default:
      return state;
  }
}
