import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import $ from 'jquery';
import Indicador from '../ui/Indicador';

import Bar from './bar';

const CAJAS_TOTALES = 144397;


const IndicadoresFinancieros = () => {
  const [indicadores, setIndicadores] = useState({});

  $(document).ready(() => {
    $('div#root').css({ backgroundColor: 'black' });
    $('.main-sidebar').css({ display: 'block' });
  });

  const indicadoresData = [
    {
      classIcon: 'fa-pie-chart',
      nombre: 'CAJAS TOTALES',
      valor: 144397,
      status: 'green-jungle',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'CAJAS HA/AÑO',
      valor: 1574.80,
      status: 'green-jungle',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'COSTO PROD./CAJA',
      valor: indicadores && indicadores.invoice ? indicadores.invoice / CAJAS_TOTALES : 0.00,
      status: 'green-jungle',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'RENTABILIDAD/CAJA',
      valor: indicadores && indicadores.invoice
        ? (indicadores.outvoice - indicadores.invoice) / CAJAS_TOTALES : 0.00,
      status: 'green-jungle',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'LIQUIDEZ',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'PRUEBA ACIDA',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'DEUDA / ACTIVO',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'DEUDA / PATRIMONIO',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'APALANCAMIENTO',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'ROTACIÓN/VENTAS',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'PERIODO/COBRANZAS',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'PERIODO/PAGOS',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'GASTOS ADM./VENTAS',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'GASTOS FIN./VENTAS',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'MARGEN OPERACIONAL',
      valor: 0.00,
      status: 'red-thunderbird',
    },
    {
      classIcon: 'fa-pie-chart',
      nombre: 'RENT. FIN.',
      valor: 0.00,
      status: 'red-thunderbird',
    },
  ];

  const indicadoresMapped = indicadoresData.map((item) => {
    const peso = item.valor;

    return (
      <div
        key={item.id}
        className="col-md-3 col-sm-6 col-xs-12"
      >
        <Indicador
          unidad=""
          nombre={item.nombre}
          peso={peso}
          porcentaje={peso}
          status={item.status}
        />
      </div>
    );
  });

  const [refreshData, setRefreshData] = useState(true);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title">Indicadores Financieros</h3>
              </div>
              <div className="page-bar box-body">
                <Bar
                  open
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}
                  setIndicadores={setIndicadores}
                />
              </div>
              <div className="box-footer" />
            </div>
          </div>
          <LoadingOverlay
            active={refreshData}
            spinner
            text="Cargando contenido"
          >
            { indicadoresMapped }
          </LoadingOverlay>
          <div className="h-divider col-md-12 col-sm-12 col-xs-12" />

        </div>
      </section>
    </div>
  );
};

export default React.memo(IndicadoresFinancieros);
