import React from 'react';

import { Flash } from 'animate-components';

const logoStyling = {
  height: '62px',
  width: '82px',
};

const ComponentLoader = () => (
  <div className="content-wrapper">
    <section id="content-body">
      <div id="mainDiv">
        <div className="box-loader">
          <Flash
            duration="2s"
            iterations="infinite"
          >
            <img className="logo-lg" alt="logoIQ" src="/logos/iqicon.png" style={logoStyling} />
          </Flash>
        </div>
      </div>
    </section>
  </div>
);

export default React.memo(ComponentLoader);
