import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import $ from 'jquery';
import { goToUrl } from '../../helpers/routing';

class TreeViewMenu extends Component {
  constructor(props) {
    super(props);

    const { name, opcion } = props;


    this.state = {
      name,
      opcion,
    };

    this.changeRoute = this.changeRoute.bind(this);
  }

  changeRoute= () => {
    const { opcion } = this.state;
    const { history, deactivateSiblings } = this.props;
    const opcionKey = Object.values(opcion) ? Object.keys(opcion)[0] : { };
    const opcionValue = Object.values(opcion) ? Object.values(opcion)[0] : { };

    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth < 900) {
      $('body').removeClass('sidebar-open');
    }

    goToUrl(history, opcionValue.link);
    deactivateSiblings(opcionKey);
  }

  render() {
    const { name, opcion } = this.state;
    const opcionValue = Object.values(opcion) ? Object.values(opcion)[0] : { };

    const anchor = (
      <a
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={this.changeRoute}
        data-link={opcion ? opcion.link : ''}
      >
        <i className={`fa ${opcionValue.fa}`} />
        {' '}
        { name }
      </a>
    );

    return (
      <>
        <li className={opcionValue.active && opcionValue.active === true ? 'active' : ''}>
          { anchor }
        </li>
      </>
    );
  }
}

TreeViewMenu.propTypes = {
  history: PropTypes.shape({}).isRequired,
  name: PropTypes.string,
  opcion: PropTypes.shape({
    active: PropTypes.bool,
    link: PropTypes.string,
  }),
  deactivateSiblings: PropTypes.func.isRequired
};

TreeViewMenu.defaultProps = {
  name: '',
  opcion: { }
};

export default withRouter(TreeViewMenu);
