import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';

import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

import {
  TTHHAPI,
  SuperficiesAPI,
  CategoriasAPI,
} from '../../../api/rest-api';
import DateHelper from '../../../helpers/global/dateHelper';
import { TIPOS_BONIFICACION } from '../../../helpers/agroiqConfig';
import to from '../../../helpers/promiseCatcher';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import styles from './barStyles';

const override = css`
    display: block;
    margin: 0 auto;
    width:50px;
    height:50px;
    border-color: #44b6ae;
    border-bottom-color: transparent;
`;


const BonificacionBar = (props) => {
  const {
    classes,
    bar,
    setBar,
    setSemanas,
    setBonificacion,
    categorias,
    setCategorias,
    setLoading,
  } = props;

  const [refresh] = useState(false);
  const [fincas, setFincas] = useState([]);
  const [tipos] = useState([
    TIPOS_BONIFICACION.PESO,
    TIPOS_BONIFICACION.MERMA,
    TIPOS_BONIFICACION.DANIO,
  ]);

  const fetchData = async () => {
    setLoading(true);
    // const [errAnios, aniosResponse] = await to(BonificacionAPI.getAnios()); /* ALGUN DIA */
    const [errSup, supResponse] = await to(SuperficiesAPI.getRaices());
    const [errCat, catResponse] = await to(
      CategoriasAPI.getCategoriasMerma({ is_bonificacion: true })
    );

    if (errSup || errCat) {
      console.error('Error cargando datos');
      setLoading(false);
      return;
    }

    const defaultCategoria = catResponse.data.find(item => item.nombre.includes('LOTE'));

    const fincaId = supResponse && supResponse.data
          && supResponse.data.length > 0 ? supResponse.data[0].id : null;
    const catId = catResponse && catResponse.data
          && catResponse.data.length > 0 ? (defaultCategoria && defaultCategoria.id)
          || catResponse.data[0].id : null;
    const [errSem, semResponse] = await to(TTHHAPI.getSemanas({
      finca_id: fincaId,
      tipo: bar.tipo,
      anio: bar.anio,
      categoria: (catResponse && catResponse.data
                  && catResponse.data.length > 0)
        ? (defaultCategoria && defaultCategoria.id) || catResponse.data[0].id : null,
    }));

    if (errSem) {
      console.error('Error cargando semanas');
      setLoading(false);
      return;
    }

    await setFincas(supResponse.data);
    await setCategorias(catResponse.data);
    await setSemanas(semResponse.data);

    const semanaId = semResponse && semResponse.data
          && semResponse.data.length > 0 ? semResponse.data[0] : null;

    await setBar({
      ...bar,
      finca: fincaId,
      categoria: catId,
      semana: semanaId,
    });

    if (semanaId) {
      const [err, bonResponse] = await to(TTHHAPI.getBonificacion({
        ...bar,
        categoria: catId,
        finca_id: fincaId,
        semana: semanaId
      }));

      setLoading(false);
      if (err) {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Algo malo ocurrió!',
          footer: '<a href>Por favor háblenos del error!</a>'
        });

        return;
      }
      setBonificacion(bonResponse.data);
    } else {
      setBonificacion([]);
    }

    setLoading(false);
  };


  /* WE JUST LOAD THE DEFAULT DATA ONCE */
  useEffect(() => {
    fetchData();
  }, []);

  const onChangeCategoria = async (value) => {
    setLoading(true);
    const [errSem, semResponse] = await to(TTHHAPI.getSemanas({
      ...bar,
      tipo: bar.tipo,
      anio: bar.anio,
      finca_id: bar.finca,
      categoria: value,
    }));

    if (errSem) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Algo malo ocurrió!',
        footer: '<a href>Por favor háblenos del error!</a>'
      });
      setLoading(false);
    }

    const semanaId = semResponse && semResponse.data
          && semResponse.data.length > 0 ? semResponse.data[0] : null;

    await setSemanas(semResponse.data);
    if (semanaId) {
      setLoading(true);
      const [err, bonResponse] = await to(TTHHAPI.getBonificacion({
        ...bar,
        semana: semanaId,
        finca_id: bar.finca,
        categoria: value
      }));

      setLoading(false);

      if (err) {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Algo malo ocurrió!',
          footer: '<a href>Por favor háblenos del error!</a>'
        });
        return;
      }

      setBonificacion(bonResponse.data);
    } else {
      setBonificacion([]);
    }

    setLoading(false);
    setBar({
      ...bar,
      categoria: value,
      semana: semanaId,
    });
  };

  const onChangeTipo = async (value) => {
    setBar({
      ...bar,
      tipo: value,
    });

    setLoading(true);
    const [err, bonResponse] = await to(
      TTHHAPI.getBonificacion({ ...bar, finca_id: bar.finca, tipo: value })
    );
    setLoading(false);
    if (err) {
      console.error(err);
      setBonificacion([]);
    } else {
      setBonificacion(bonResponse.data);
    }
  };

  const onChangeFinca = async (value) => {
    setLoading(true);
    const [errSem, semResponse] = await to(TTHHAPI.getSemanas({
      ...bar,
      finca_id: value,
      tipo: bar.tipo,
      anio: bar.anio,
    }));

    if (errSem) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Algo malo ocurrió!',
        footer: '<a href>Por favor háblenos del error!</a>'
      });
      setLoading(false);
    }

    const semanaId = semResponse && semResponse.data
          && semResponse.data.length > 0 ? semResponse.data[0] : null;

    if (semanaId) {
      setLoading(true);
      const [err, bonResponse] = await to(
        TTHHAPI.getBonificacion({ ...bar, finca_id: value, semana: semanaId })
      );

      if (err) {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Algo malo ocurrió!',
          footer: '<a href>Por favor háblenos del error!</a>'
        });
      }
      setBonificacion(err ? [] : bonResponse.data);
    } else {
      setBonificacion([]);
    }

    setLoading(false);

    await setSemanas(errSem ? [] : semResponse.data);
    setBar({ ...bar, semana: semanaId, finca: value });
  };

  const onChangeAnio = async (value) => {
    setBar({
      ...bar,
      finca: value,
    });

    if (bar.semana) {
      setLoading(true);
      const [err, bonResponse] = await to(TTHHAPI.getBonificacion({ ...bar, anio: value }));
      setLoading(false);
      if (err) {
        setBonificacion([]);
      } else {
        setBonificacion(bonResponse.data);
      }
    } else {
      setBonificacion([]);
    }
  };


  const categoriasJSX = categorias.map(
    item => (<option key={item.id} value={item.id}>{item.nombre}</option>)
  );

  const tiposJSX = tipos.map(
    item => (<option key={item} value={item}>{(item === 1 && 'Peso') || (item === 2 && 'Merma') || (item === 3 && 'Daño') }</option>)
  );

  const fincasJSX = fincas.map(
    finca => (<option key={finca.id} value={finca.id}>{finca.nombre}</option>)
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" className={classes.root}>
        <Toolbar>
          <div className="sweet-loading">
            <ClipLoader
              css={override}
              sizeUnit="px"
              size={150}
              color="#123abc"
              loading={refresh}
            />
          </div>
          <div className={classes.grow} />
          <div style={{
            display: 'inline-flex', float: 'right', lineHeight: '200%', paddingRight: '5px'
          }}
          />

          <div style={{ paddingRight: '5px', textAlign: 'center' }}>Finca:</div>
          <div style={{
            display: 'inline-flex', float: 'right', lineHeight: '200%', paddingRight: '5px'
          }}
          >
            <div style={{ alignSelf: 'center', paddingRight: '5px' }}>
              <select
                name="idFinca"
                className="form-control select-agroiq"
                style={{ margin: '2px', textTransform: 'capitalize' }}
                value={(bar.finca && bar.finca.toString()) || ''}
                onChange={(evt) => {
                  onChangeFinca(evt.target.value);
                }}
              >
                {fincasJSX}
              </select>
            </div>
          </div>

          <div style={{ paddingRight: '5px', textAlign: 'center' }}>Tipo:</div>
          <div style={{
            display: 'inline-flex', float: 'right', lineHeight: '200%', paddingRight: '5px'
          }}
          >
            <div style={{ alignSelf: 'center', paddingRight: '5px' }}>
              <select
                name="idTipo"
                className="form-control select-agroiq"
                style={{ margin: '2px', textTransform: 'capitalize' }}
                value={bar.tipo && bar.tipo.toString()}
                onChange={(evt) => {
                  onChangeTipo(Number(evt.target.value));
                }}
              >
                {tiposJSX}
              </select>
            </div>
          </div>
          <div style={{ paddingRight: '5px', textAlign: 'center' }}>Categor&iacute;a:</div>

          <div style={{
            display: 'inline-flex', float: 'right', lineHeight: '200%', paddingRight: '5px'
          }}
          >
            <div style={{ alignSelf: 'center', paddingRight: '5px' }}>
              <select
                name="idCat"
                id="idCat"
                className="form-control select-agroiq"
                style={{ margin: '2px', textTransform: 'capitalize' }}
                value={(bar.categoria && bar.categoria.toString()) || ''}
                onChange={(evt) => {
                  onChangeCategoria(Number(evt.target.value));
                }}
              >
                {categoriasJSX}
              </select>
            </div>
          </div>
          <div />
        </Toolbar>
      </AppBar>
    </div>

  );
};

BonificacionBar.defaultProps = {
  bar: {
    anio: Number(DateHelper.getCurrentYear()),
    semana: null,
    finca: null,
    tipo: TIPOS_BONIFICACION.DANIO,
    categoria: null
  }
};

BonificacionBar.propTypes = {
  bar: PropTypes.shape({
    anio: PropTypes.number.isRequired,
    semana: PropTypes.number,
    finca: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tipo: PropTypes.number.isRequired,
    categoria: PropTypes.number,
  }),
  setBar: PropTypes.func.isRequired,
  setSemanas: PropTypes.func.isRequired,
  setBonificacion: PropTypes.func.isRequired,
  setCategorias: PropTypes.func.isRequired,
  categorias: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired })).isRequired,
  setLoading: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired
};

export default React.memo(withStyles(styles)(BonificacionBar));
