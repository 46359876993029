import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const UserInfo = (props) => {
  const { user } = props;

  const redirectToProfile = () => {
    const { history } = props;
    history.push({
      pathname: '/profile',
    });
  };

  return (
    <div className="user-panel">
      <div className="pull-left info">
        <p>{ user != null ? user.name : '' }</p>
        <a
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={redirectToProfile}
        />
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  user: PropTypes.shape({ name: PropTypes.string.isRequired }),
  history: PropTypes.shape({}).isRequired
};

UserInfo.defaultProps = {
  user: null,
};

export default withRouter(UserInfo);
