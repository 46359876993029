import DateHelper from '../../helpers/global/dateHelper';

import {
  MermaDiarioAPI,
  CategoriasAPI,
  SuperficiesAPI,
} from '../../api/rest-api';

import { getSelectedFincaFromFincas } from '../../helpers/fincasHelpers';
import { getSelectedCategoria } from '../../helpers/categoriaHelper';

import { setFincasNew, justSelectFinca } from './fincas';
import { setFecha } from './fecha';
import { setBar } from './bar';
import { setCategorias, selectCategoria } from './categorias';

export const SET_MERMA_DIARIO = 'SET_MERMA_DIARIO';

const initialState = {};

const setMermaDiario = data => (dispatch) => {
  dispatch({
    type: SET_MERMA_DIARIO,
    payload: {
      data
    }
  });
};


/* @param param {}, {fecha, finca,categoria } */
export const cargarDashboard = params => async (dispatch, getState) => {
  const { fecha: newFecha, finca, categoria } = params;
  const { fincas: fincasState } = getState();
  if (!fincasState.datos || fincasState.datos.length === 0) {
    try {
      const fincasResponse = await SuperficiesAPI.getRaicesFromMerma();
      await dispatch(setFincasNew(fincasResponse.data));
    } catch (err) {
      console.error(err);
    }
  }

  if (finca) {
    await dispatch(justSelectFinca(finca));
  }

  if (newFecha) {
    await dispatch(setFecha(newFecha || new Date()));
  }

  const { fincas } = getState();

  const selectedFinca = getSelectedFincaFromFincas(fincas.datos || []);

  if (!newFecha) {
    const mermaCategoriasResponse = await CategoriasAPI.getCategoriasMermaDiario({
      finca: selectedFinca ? selectedFinca.id : ''
    });

    await dispatch(setCategorias(mermaCategoriasResponse.data));

    const categoriasData = mermaCategoriasResponse.data;
    if (categoriasData && categoriasData.length > 0) {
      await dispatch(
        selectCategoria(categoriasData[0].id)
      );
    }
  }

  if (categoria) {
    await dispatch(selectCategoria(categoria));
  }

  const { categorias } = getState();

  const selectedCategoria = getSelectedCategoria(categorias);

  if (!newFecha) {
    const responseBar = await MermaDiarioAPI.getMermaDiarioBar({
      finca: selectedFinca ? selectedFinca.id : 'todos',
      categoria: selectedCategoria ? selectedCategoria.id : null
    });

    await dispatch(setBar(responseBar.data));
  }

  const { bar } = getState();

  if (!newFecha) {
    await dispatch(setFecha((bar && bar.diario
                             && bar.diario.length > 0
                             && bar.diario[0]
                             && DateHelper.getDateFromString(bar.diario[0])
    )
                            || new Date()));
  }

  const { fecha: fechaState } = getState();

  const fechaReq = DateHelper.getDateFromString(fechaState);
  const mermaDiario = await MermaDiarioAPI.getMermaDiario({
    fecha: DateHelper.getDateFromString(fechaReq, 'YYYY-MM-DD'),
    categoria_id: selectedCategoria ? selectedCategoria.id : null,
    finca_id: selectedFinca ? selectedFinca.id : null
  });

  await dispatch(setMermaDiario(mermaDiario.data || {}));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MERMA_DIARIO: {
      return { ...action.payload.data };
    }

    default:
      return state;
  }
}
