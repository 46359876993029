export const SET_FECHA = 'SET_FECHA';

const initialState = new Date();

export const setFecha = fecha => ({
  type: SET_FECHA,
  payload: {
    fecha
  }
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FECHA: {
      return action.payload.fecha;
    }

    default:
      return state;
  }
}
