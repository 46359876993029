const goToUrl = (history, url) => {
  if (url.includes('http')) {
    window.location.href = url;
  } else {
    history.push({
      pathname: url,
    });
  }
};

export { goToUrl };
