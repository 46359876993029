export const SET_BAR = 'SET_BAR';

const initialState = { };

export const setBar = (valor) => ({
  type: SET_BAR,
  payload: valor
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BAR: {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
};

export default reducer;
