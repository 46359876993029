const DEVELOPMENT = process.env.NODE_ENV === "development";

const dashboardRoute = "/dashboard";

const OPCIONES = {
  Calidad: {
    icon: "winner",
    children: [
      {
        "Reporte Día": {
          active: false,
          link: `${dashboardRoute}/calidad/dia`,
          fa: "fa-bar-chart",
        },
      },
      {
        Comparación: {
          active: false,
          link: `${dashboardRoute}/calidad/comparativo`,
          fa: "fa-bar-chart",
        },
      },
      {
        Tendencia: {
          active: false,
          link: `${dashboardRoute}/calidad/tendencia`,
          fa: "fa-bar-chart",
        },
      },
    ],
  },
  Producción: {
    icon: "bananas",
    children: [
      {
        "Fuentes de Información": {
          active: false,
          link: "",
          children: [
            {
              Cajas: {
                active: false,
                link: `${dashboardRoute}/produccion/cajas`,
                fa: "fa-suitcase",
              },
            },
          ],
        },
      },
      {
        "Balanza de Racimos": {
          active: false,
          link: "http://app.procesos-iq.com/produccionRacimos",
          fa: "fa-balance-scale",
        },
      },
      {
        "Balanza de Cajas": {
          active: false,
          link: "http://app.procesos-iq.com/produccioncajas",
          fa: "fa-cubes",
        },
      },
    ],
  },
  "Labores Agrícolas": {
    icon: "farmer",
    children: [
      {
        Reporte: {
          active: false,
          link: `${dashboardRoute}/labores/reporte`,
          icons: "farmer",
          fa: "fa-balance-scale",
          children: [
            {
              Día: {
                active: false,
                link: `${dashboardRoute}/labores/diario`,
                fa: "fa-calendar",
              },
            },
            {
              Comparativo: {
                active: false,
                link: `${dashboardRoute}/labores/comparativo`,
                fa: "fa-random",
              },
            },
            {
              Tendencia: {
                active: false,
                link: `${dashboardRoute}/labores/tendencia`,
                fa: "fa-bar-chart",
              },
            },
          ],
        },
      },
    ],
  },
  Merma: {
    icon: "decrease",
    children: [
      {
        "Merma Comparativo": {
          active: false,
          link: `http://app.procesos-iq.com/executiveresume`,
          fa: "fa-bar-chart",
        },
      },
      {
        "Merma Tendencia": {
          active: false,
          link: `http://app.procesos-iq.com/dashboardmermatblu`,
          fa: "fa-bar-chart",
        },
      },
      {
        "Merma Por Sector": {
          active: false,
          link: `http://app.procesos-iq.com/mermasector`,
          fa: "fa-bar-chart",
        },
      },
      {
        General: {
          active: false,
          link: `${dashboardRoute}/merma/general`,
          fa: "fa-bar-chart",
        },
      },
      {
        "Merma por día": {
          active: false,
          link: `${dashboardRoute}/merma/diario`,
          fa: "fa-bar-chart",
        },
      },
      {
        Bonificación: {
          active: false,
          link: `${dashboardRoute}/tthh/bonificacion`,
          fa: "fa-dollar",
        },
      },
    ],
  },
};

const USUARIOS = {
  "agroiq-ginafruit": {
    nombres: "Gina",
    compania: "Ginafruit",
    logoName: "/logos/iqicon.png",
  },
  "agroiq-lara": {
    nombres: "Mario Lara",
    compania: "Lara",
    logoName: "/logos/iqicon.png",
  },
  "agroiq-ruforcorp": {
    nombres: "Ruforcorp",
    compania: "Ruforcorp",
    logoName: "/logos/ruforcorp.png",
  },
  ruforcorp: {
    nombres: "Ruforcorp",
    compania: "Ruforcorp",
    logoName: "/logos/ruforcorp.png",
  },
  "agroiq-maeugenia": {
    nombres: "Maria Eugenia",
    compania: "Maeugenia",
    logoName: "/logos/iqicon.png",
  },
  "agroiq-orodelti": {
    nombres: "Orodelti",
    compania: "Orodelti",
    logoName: "/logos/iqicon.png",
  },
  lara: {
    nombres: "Mario Lara",
    compania: "Lara",
    logoName: "/logos/iqicon.png",
  },
  mario: {
    nombres: "Mario Lara",
    compania: "Lara",
    logoName: "/logos/iqicon.png",
  },
  "agroiq-marlon": {
    nombres: "Marlon",
    compania: "marlon",
    logoName: "/logos/iqicon.png",
  },
  marlon: {
    nombres: "Marlon",
    compania: "marlon",
    logoName: "/logos/iqicon.png",
  },
  "agroiq-marun": {
    nombres: "Jorge Marun",
    compania: "Marun",
    logoName: "/logos/iqicon.png",
  },
  "agroiq-moran": {
    nombres: "Bonifacio Moran",
    compania: "Moran",
    logoName: "/logos/iqicon.png",
  },
  "agroiq-arregui": {
    nombres: "Arregui",
    compania: "Arregui",
    logoName: "/logos/iqicon.png",
  },
};

const usuarioNoDefinido = {
  nombres: "No Definido",
  compania: "No definido",
  logoName: "/logos/iqicon.png",
};

const getSubdomain = () => {
  const { host } = window.location;
  const parts = host.split(".");
  const subdomain = parts && parts[0] ? parts[0] : "error";

  return subdomain;
};

const getNameFromRoute = (pathname) => {
  switch (pathname) {
    case `${dashboardRoute}/labores/diario`: {
      return "Reporte Día";
    }

    case `${dashboardRoute}/labores/tendencia`: {
      return "Reporte Tendencia";
    }

    case `${dashboardRoute}/labores/comparativo`: {
      return "Reporte Comparativo";
    }

    case `${dashboardRoute}/calidad/comparativo`: {
      return "Reporte Comparativo";
    }

    case `${dashboardRoute}/calidad/dia`: {
      return "Reporte Diario";
    }

    case `${dashboardRoute}/calidad/tendencia`: {
      return "Reporte Tendencia";
    }

    default:
      return "";
  }
};

const getLogoFromRoute = (pathname) => {
  if (pathname.includes("labores")) return "/logos/agroaudit.png";
  if (pathname.includes("merma")) return "/logos/merma.png";
  if (pathname.includes("bonificacion")) return "/logos/merma.png";
  if (pathname.includes("calidad")) return "/logos/calidad.png";
  return "";
};

const getUsuarioDataFromConfig = () => {
  try {
    const key = getSubdomain();
    if (USUARIOS[key]) return USUARIOS[key];
    throw new Error("No se encontro al usuario");
  } catch (e) {
    console.error(e);
    return usuarioNoDefinido;
  }
};

const DEFAULT_TIPO_REPORTE = "diario";
const TIPOS_REPORTE = ["diario", "semanal", "periodal"];

if (!["ginafruit", "agroiq-ginafruit"].includes(getSubdomain())) {
  delete OPCIONES["Calidad"];
}

if (["moran", "agroiq-moran"].includes(getSubdomain())) {
  delete OPCIONES["Producción"];
  delete OPCIONES["Labores Agrícolas"];
}

if (["arregui", "agroiq-arregui"].includes(getSubdomain())) {
  delete OPCIONES["Labores Agrícolas"];
}

const TIPOS_BONIFICACION = {
  PESO: 1,
  MERMA: 2,
  DANIO: 3,
};

const SEMANA = "sem";
const PERIODO = "periodo";
const ZONA = 1;
const FINCA = 2;
const CALIDAD_CLUSTER = 1;
const CALIDAD_DEDOS = 2;
const CALIDAD_EMPAQUE = 3;
const CALIDAD_CANT_CLUSTER = 4;
const CALIDAD_CANT_DEDOS = 5;
const PESO_PROM_CLUSTER = 6;

const CALIDAD_TIPOS_MARCA_TIPO_CAJAS = {
  CLUSTER: 0,
  MANO: 1,
};

const CALIDAD_INDICADORES = {
  CALIDAD_CLUSTER: 1,
  CALIDAD_DEDOS: 2,
  CALIDAD_EMPAQUE: 3,
  CLUSTER_PROM: 4,
  PESO_PROM_CLUSTER: 5,
  DEDOS_PROM_CAJA: 6,
};

const UNIDADES = {
  LB: 0,
  KG: 1,
};

export {
  CALIDAD_TIPOS_MARCA_TIPO_CAJAS,
  OPCIONES,
  getUsuarioDataFromConfig,
  getLogoFromRoute,
  getNameFromRoute,
  DEFAULT_TIPO_REPORTE,
  TIPOS_REPORTE,
  getSubdomain,
  TIPOS_BONIFICACION,
  SEMANA,
  PERIODO,
  ZONA,
  FINCA,
  CALIDAD_CLUSTER,
  CALIDAD_DEDOS,
  CALIDAD_EMPAQUE,
  CALIDAD_CANT_CLUSTER,
  CALIDAD_CANT_DEDOS,
  PESO_PROM_CLUSTER,
  CALIDAD_INDICADORES,
  UNIDADES,
};
