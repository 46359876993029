import { LOADING } from '../../helpers/statusStates';

import { updateStatus } from './status';

const SET_MERMA_HOY = 'SET_MERMA_HOY';
export const RESET_MERMA_HOY = 'RESET_MERMA_HOY';

const initialState = {};

export function setMermaHoy(merma) {
  return {
    type: SET_MERMA_HOY,
    payload: { merma }
  };
}

export const resetMermaHoy = () => (dispatch) => {
  dispatch({
    type: RESET_MERMA_HOY,
    payload: {}
  });

  dispatch(updateStatus({ mermaHoy: LOADING }));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MERMA_HOY: {
      return { ...state, ...action.payload };
    }

    case RESET_MERMA_HOY: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
