import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TIPOS_BONIFICACION } from '../../../../helpers/agroiqConfig';

const uuidv4 = require('uuid/v4');

const COLORES = {
  VERDE: 1,
  AMARILLO: 2,
  ROJO: 3
};


class TBodyRow extends Component {
  constructor(props) {
    super(props);

    const { data: rowData } = props;

    this.state = {
      rowData,
      childrenShown: false,
    };

    this.onClickRow = this.onClickRow.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.rowData) {
      return ({
        rowData: nextProps.data,
      });
    }
    return prevState;
  }

    onClickRow = () => {
      const { childrenShown } = this.state;

      this.setState({
        childrenShown: !childrenShown
      });
    }

    render() {
      const {
        children,
        bonificacion,
        cotizaciones,
        tipo,
      } = this.props;

      const { rowData, childrenShown } = this.state;
      const trClass = 'pointer main-row ng-scope';

      let className = '';

      let childrenJSX = (<React.Fragment />);

      if (rowData.length >= 2 && childrenShown) {
        childrenJSX = children.map((c) => {
          const childRow = c.map((child, index) => {
            if (index === 0) {
              return (<td key={uuidv4()} colSpan={2} className="muestra-title">{ `${child}`}</td>);
            }
            return (<td key={uuidv4()} className="muestra">{ child ? `${child}` : '-' }</td>);
          });
          return (
            <tr key={uuidv4()}>
              { childRow }
            </tr>
          );
        });
      }

      if (!rowData) {
        return (<> </>);
      }

      const rowDataJSX = rowData.map((row, index) => {
        if (index === rowData.length - 3) {
          const cotizacion = (cotizaciones || []).find((cot) => {
            return cot.maximo >= Number(row) && Number(row) >= cot.minimo
          });
          if (cotizacion && tipo === TIPOS_BONIFICACION.DANIO) {
            if (cotizacion.color.toString() === COLORES.VERDE.toString()) className = 'greenTd';
            else if (cotizacion.color.toString() === COLORES.AMARILLO.toString()) className = 'yellowTd';
            else if (cotizacion.color.toString() === COLORES.ROJO.toString()) className = 'redTd';
            return (<td key={uuidv4()} className={className}>{ row ? `${row}` : '-' }</td>);
          }
          return (<td key={uuidv4()} className="">{ row ? `${row}` : '-' }</td>);
        }
        if (index === 0) {
          return (
            <td key={uuidv4()} className={className}>{ row }</td>
          );
        }

        return (<td key={uuidv4()} className="">{ row ? `${row}` : '-' }</td>);
      });
      return (
        <>
          <tr key={uuidv4()} onClick={this.onClickRow} className={trClass}>
            {rowDataJSX}
          </tr>
          { childrenJSX }
        </>
      );
    }
}

TBodyRow.defaultProps = {
  bonificacion: null
};

TBodyRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType(
    [PropTypes.shape({}), PropTypes.number, PropTypes.string]
  )).isRequired,
  cotizaciones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tipo: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  bonificacion: PropTypes.shape({}),
};

export default React.memo(TBodyRow);
