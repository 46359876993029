import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { getUsuarioDataFromConfig, getLogoFromRoute } from '../helpers/agroiqConfig';
import { goToUrl } from '../helpers/routing';

const logoStyling = {
  height: '100%',
  width: '100%',
};

const mermaLogoStyling = {
  height: 'auto',
  width: '100px',
  marginTop: '20px',
  marginLeft: '10px'
};

const { logoName } = getUsuarioDataFromConfig();

const Header = (props) => {
  const { history, location } = props;
  let logo = '';
  if (location) {
    logo = getLogoFromRoute(location.pathname);
  }
  return (
    <header className="main-header">
      <a
        href="#"
        className="logo"
        onClick={
           () => {
             goToUrl(history, '/');
           }}
      >
        <span className="logo-mini">
          <img
            className="logo-lg"
            alt="logo-lg"
            src="/iqicon.png"
            style={logoStyling}
          />
        </span>
        <span className="logo-lg">
          <img
            className="logo-lg"
            alt="logo-lg"
            src="/images/agroiq.png"
            style={logoStyling}
          />
        </span>
      </a>
      <nav className="navbar navbar-static-top">
        <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
          <span className="sr-only">Toggle navigation</span>
        </a>
        <img
          className="logo-lg"
          alt="logo-lg"
          src={logo}
          style={mermaLogoStyling}
        />

        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li className="dropdown user user-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <img src={logoName || ''} className="logo-company" alt="Compania" />
                <span className="hidden-xs">{}</span>
              </a>
              <ul className="dropdown-menu">
                <li className="user-footer">
                  <div className="pull-right" style={{ width: '100%' }}>
                    <a
                      onClick={
                        () => {
                          goToUrl(history, '/');
                        }
                      }
                      href="#"
                      className="btn btn-default btn-flat"
                      style={{ width: '100%' }}
                    >
Cerrar sesión
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default React.memo(withRouter(Header));
