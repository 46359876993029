import { LOADING } from '../../helpers/statusStates';

import { updateStatus } from './status';

const SET_MERMA_HISTORICO = 'SET_MERMA_HISTORICO';
export const RESET_MERMA_HISTORICO = 'RESET_MERMA_HISTORICO';

/*
  Podemos tener acumuladas varias mermas por
  cada grafica que tenemos dentro de nuestra aplicacion.

*/

const initialState = {};

export function setMermaHistorico(merma) {
  return {
    type: SET_MERMA_HISTORICO,
    payload: { merma }
  };
}

export const resetMermaHistorico = () => (dispatch) => {
  dispatch({
    type: RESET_MERMA_HISTORICO,
    payload: {}
  });

  dispatch(updateStatus({ mermaGlobalType: LOADING }));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MERMA_HISTORICO: {
      return { ...state, ...action.payload };
    }

    case RESET_MERMA_HISTORICO: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
