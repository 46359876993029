const LOAD_MERMA_HISTORICO = 'LOAD_MERMA_HISTORICO';

export function loadMermaHistoricaFromDateRange(anio, start_date, end_date, finca_id) {
  return {
    type: LOAD_MERMA_HISTORICO,
    payload: {
      request: {
        url: 'v1/merma/mermaneta/',
        params: {
          year: anio,
          start_date,
          end_date,
          finca_id
        }
      }
    }
  };
}
