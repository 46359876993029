const LOAD_MERMA_HOY = 'LOAD_MERMA_HOY';

export function loadMermaHoy(start_date, end_date, finca_id) {
  return {
    type: LOAD_MERMA_HOY,
    payload: {
      request: {
        url: '/v1/merma/mermaneta/',
        params: {
          start_date,
          end_date,
          finca_id
        }
      }
    }
  };
}
