import React from 'react';

import ReactEcharts from 'echarts-for-react';

const CalidadHistorico = (props) => {
  const data = {
    legend: {
        data:['Calidad Dedos']
    },
    xAxis: {
      type: 'category',
      data: ['10',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',]
    },
    yAxis: {
      type: 'value',
      scale: true,
    },
    series: [{
      name: 'Calidad Dedos',
      data: [97.1,
        96.2,
        93.2,
        95,
        96.2,
        96.2,
        96.2,
        96.2,
        96.2,
        96.2,
        93.2,
        95,
        93.2,
        95,
        93.2,
        95,
        93.2,
        95,
        93.2,
        95,
        93.2,
        95,],
      type: 'line'
    }]
  };

  return (
    <div className="portlet light portlet-fit ">
      <div className="portlet-title">
        <div className="caption">
          <i className=" icon-layers font-green" />
          <span className="caption-subject font-green bold uppercase ng-binding">CALIDAD HISTÓRICO - Calidad</span>
        </div>
        <div className="actions" ng-init="showCalidad = 1;">
          <a className="btn btn-success" href="unsafe:javascript:;" ng-click="initGrafica('highstock_calidad_historica', data_grafica_calidad);">
                        Calidad
          </a>
          <a className="btn btn-warning" href="unsafe:javascript:;" ng-click="initGrafica('highstock_calidad_historica', data_grafica_de);">
                        Desviación Estándar
          </a>
          <a className="btn btn-danger" href="unsafe:javascript:;" ng-click="initGrafica('highstock_calidad_historica', data_grafica_peso);">
                        Peso caja
          </a>
          <a className="btn btn-info" href="unsafe:javascript:;" ng-click="initGrafica('highstock_calidad_historica', data_grafica_peso_cluster);">
                        Peso clúster
          </a>
          <a className="btn btn-primary" href="unsafe:javascript:;" ng-click="initGrafica('highstock_calidad_historica', data_grafica_cluster);">
                        Clúster
          </a>
        </div>
      </div>
      <div className="portlet-body">
        <ReactEcharts
          option={data}
          notMerge
          lazqyUpdate
        />
      </div>
    </div>
  );
};

export default React.memo(CalidadHistorico);
