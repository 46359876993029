import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserInfo from './sidebar/UserInfo';
import TreeView from './sidebar/TreeView';

/* Importamos la configuracion del sidebar por default. */
import { OPCIONES, getUsuarioDataFromConfig } from '../helpers/agroiqConfig';

const { nombres } = getUsuarioDataFromConfig();


class SideBar extends Component {
  constructor(props) {
    super(props);

    /* ESTADO INICIAL: TENEMOS LAS OPCIONES DEL SIDEBAR A RENDERIZAR */
    this.deactivateSiblings = this.deactivateSiblings.bind(this);
  }

  componentWillMount() {
    const { setOpciones } = this.props;
    setOpciones(OPCIONES);
  }

    deactivateSiblings = rootKey => parentKey => (optionSelected) => {
      const { opciones: opcionesData, setOpciones } = this.props;
      try {
        const opciones = { ...opcionesData };
        const root = opciones[rootKey];
        root[parentKey][optionSelected].active = true;
        root[parentKey].active = true;

        root.forEach((element, i, arr) => {
          if (parentKey !== i) {
            arr[i][Object.keys(element)[0]].active = false;
            arr[i].active = false;
          }
        });

        const siblings = root.filter((element, i) => parentKey !== i);

        siblings.forEach((child) => {
          child[Object.keys(child)[0]].active = false;
        });

        const otherRoots = Object.keys(opciones).filter(key => key !== rootKey);

        otherRoots.forEach((i) => {
          opciones[i].active = false;
          opciones[i].forEach((child) => {
            child[Object.keys(child)[0]].active = false;
          });
        });

        setOpciones(opciones);
      } catch (e) {
        console.error('Error inesperado :c', e);
      }
    }

    render() {
      const { opciones } = this.props;
      let treeviewChildren = null;
      if (opciones) {
        treeviewChildren = Reflect.ownKeys(opciones).map(
          key => (
            <TreeView
              key={key}
              opcion={opciones[key]}
              name={key}
              deactivateSiblings={this.deactivateSiblings(key)}
            />
          )
        );
      } else {
        treeviewChildren = <div />;
      }
      return (
        <>
          <aside className="main-sidebar">
            <section className="sidebar">
              <UserInfo user={{ name: nombres }} />
              <ul className="sidebar-menu" data-widget="tree">
                { treeviewChildren }
              </ul>
            </section>
          </aside>
          <script src="/bower_components/jquery/dist/jquery.min.js" />
          <script src="/bower_components/jquery-ui/jquery-ui.min.js" />
          <script src="/bower_components/bootstrap/dist/js/bootstrap.min.js" />
          <script src="/bower_components/raphael/raphael.min.js" />
          <script src="/bower_components/jquery-sparkline/dist/jquery.sparkline.min.js" />
          <script src="/plugins/jvectormap/jquery-jvectormap-1.2.2.min.js" />
          <script src="/plugins/jvectormap/jquery-jvectormap-world-mill-en.js" />
          <script src="/bower_components/jquery-knob/dist/jquery.knob.min.js" />
          <script src="/plugins/bootstrap-wysihtml5/bootstrap3-wysihtml5.all.min.js" />
          <script src="/bower_components/jquery-slimscroll/jquery.slimscroll.min.js" />
          <script src="/bower_components/fastclick/lib/fastclick.js" />
          <script src="/js/adminlte.min.js" />
        </>
      );
    }
}


SideBar.propTypes = {
  opciones: PropTypes.shape({}).isRequired,
  setOpciones: PropTypes.func.isRequired,
};

export default SideBar;
