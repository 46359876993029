import React, { useState } from 'react';
import $ from 'jquery';

import Indicador from '../ui/Indicador';
import Bar from './bar';
import ResumenLote from './resumenLote';
import CalidadHistorico from './calidadHistorico';

const indicadoresData = [
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: 'FINCAS',
    valor: 4,
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: '% Calidad',
    valor: 1574.80,
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: '% Calidad Puerto',
    valor: 6.23,
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: 'Cajas Totales',
    valor: 1.23,
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: 'Inspeccionados',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: '# Recusados',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: 'Calibracion AVG',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: 'Edad',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['puerto'],
    nombre: 'Cajas Puerto',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['puerto'],
    nombre: 'Cajas Tránsito',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    tipo: ['general'],
    nombre: 'Manos Promedio',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['general'],
    nombre: 'Cant. Inspecciones',
    valor: 0.00,
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['puerto'],
    nombre: 'Hora de llegada primera caja',
    valor: '13:23:08',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['puerto'],
    nombre: 'Hora de llegada ultima caja',
    valor: '12:00:12',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'PRIMER RACIMO DEL DIA',
    valor: '06:37:35',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'ULTIMO RACIMO DEL DIA',
    valor: '12:58:12',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'HORAS DE PRODUCCION',
    valor: '06:20:37',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'COSECHADOS',
    valor: '820',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-bar-chart',
    tipo: ['produccion'],
    nombre: 'PROCESADOS',
    valor: '811',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-bar-chart',
    tipo: ['produccion'],
    nombre: '% DE MUESTREO (MANOS)',
    valor: '100',
    porcentaje: 100,
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-bar-chart',
    tipo: ['produccion'],
    nombre: 'CALIBRACIÓN SEGUNDA PROM ',
    valor: '43.95',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'MANOS PROM',
    valor: '8.11',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-bar-chart',
    tipo: ['produccion'],
    nombre: 'EDAD PROM',
    valor: '12.25',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'PESO LB PROC',
    valor: '52514.09',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'PESO LB RECU',
    valor: '475.96',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['produccion'],
    nombre: 'PESO NETO PROM RACIMO Lb',
    valor: '64.62',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['cajas'],
    nombre: 'CALIDAD DEDOS',
    valor: '98.01',
    porcentaje: '98.01',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['cajas'],
    nombre: 'CALIDAD CLUSTER',
    valor: '88.73',
    porcentaje: '88.73',
    status: 'yellow-lemon',
  },
  {
    classIcon: 'fa-bar-chart',
    tipo: ['cajas'],
    nombre: 'CALIDAD MAXIMA CLUSTER',
    valor: '91.67',
    porcentaje: '91.67',
    status: 'green-jungle',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['cajas'],
    nombre: 'CALIDAD MINIMA CLUSTER',
    valor: '83.33',
    porcentaje: '83.33',
    status: 'red-thunderbird',
  },
  {
    tipo: ['cajas'],
    nombre: 'DESVIACION ESTANDAR',
    valor: '0.43',
    icon: '/logos/variation.svg',
    status: 'red-thunderbird',
  },
  {
    tipo: ['cajas'],
    nombre: 'PESO',
    valor: '12.64',
    classIcon: 'fa-balance-scale',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['cajas'],
    nombre: '#CLUSTER',
    valor: '11.67',
    status: 'red-thunderbird',
  },
  {
    classIcon: 'fa-pie-chart',
    tipo: ['cajas'],
    nombre: 'DEDOS PROMEDIO',
    valor: '66.50',
    status: 'red-thunderbird',
  },
];

const IndicadoresFinancieros = () => {
  $(document).ready(() => {
    $('div#root').css({ backgroundColor: 'black' });
    $('.main-sidebar').css({ display: 'block' });
  });
  const [tab, selectTab] = useState(0);

  const setTab = (index) => {
    selectTab(index);
  };

  const tabs = ['General', 'Puerto', 'Gancho', 'Cajas'].map((item, index) => {
    const selected = tab === index;
    return (
      <li
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        key={`nav${item}`}
        value={index}
        className={`nav-item ${selected ? 'active' : ''}`}
        onClick={() => {
          setTab(index);
        }}
      >
        <a data-value={index} className={`nav-link${selected ? 'active' : ''}`} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected={!!selected}>{ item }</a>
      </li>
    );
  });

  let indicadoresMapped = (<React.Fragment />);
  if (tab === 0) {
    indicadoresMapped = indicadoresData.filter(item => item.tipo.includes('general')).map(item => (
      <div
        key={item.id}
        className="col-md-3 col-sm-6 col-xs-12"
      >
        <Indicador
          unidad=""
          nombre={item.nombre}
          peso={item.valor}
          porcentaje={null}
          status={item.status}
          classIcon={item.classIcon}
          icon={item.icon}
          display={item.display}
        />
      </div>
    ));
  } else if (tab === 1) {
    indicadoresMapped = indicadoresData.filter(item => item.tipo.includes('puerto')).map(item => (
      <div
        key={item.id}
        className="col-md-3 col-sm-6 col-xs-12"
      >
        <Indicador
          unidad=""
          nombre={item.nombre}
          peso={item.valor}
          porcentaje={null}
          status={item.status}
          classIcon={item.classIcon}
          icon={item.icon}
          display={item.display}
        />
      </div>
    ));
  } else if (tab === 2) {
    indicadoresMapped = indicadoresData.filter(item => item.tipo.includes('produccion')).map(item => (
      <div
        key={item.id}
        className="col-md-3 col-sm-6 col-xs-12"
      >
        <Indicador
          unidad=""
          nombre={item.nombre}
          peso={item.valor}
          porcentaje={null}
          status={item.status}
          classIcon={item.classIcon}
          icon={item.icon}
          display={item.display}
        />
      </div>
    ));
  } else if (tab === 3) {
    indicadoresMapped = indicadoresData.filter(item => item.tipo.includes('cajas')).map(item => (
      <div
        key={item.id}
        className="col-md-3 col-sm-6 col-xs-12"
      >
        <Indicador
          unidad=""
          nombre={item.nombre}
          peso={item.valor}
          porcentaje={null}
          status={item.status}
          classIcon={item.classIcon}
          icon={item.icon}
          display={item.display}
        />
      </div>
    ));
  } else {
    indicadoresMapped = indicadoresData.map(item => (
      <div
        key={item.id}
        className="col-md-3 col-sm-6 col-xs-12"
      >
        <Indicador
          unidad="%"
          nombre={item.nombre}
          peso={item.valor}
          porcentaje={item.valor}
          status={item.status}
          classIcon={item.classIcon}
          icon={item.icon}
          display={item.display}
        />
      </div>
    ));
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title">GinaQuality</h3>
              </div>
              <div className="page-bar box-body">
                <Bar
                  open
                />
              </div>
              <div className="box-footer">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      { tabs }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          { indicadoresMapped }
          <div className="h-divider col-md-12 col-sm-12 col-xs-12" />

          {tab === 2 && (
          <div className="col-md-12 col-sm-12 col-xs-12">
            <ResumenLote />
          </div>
          )
    }

          {tab === 3 && (
          <div className="col-md-12 col-sm-12 col-xs-12">
            <CalidadHistorico />
          </div>
          )
    }
        </div>
      </section>
    </div>
  );
};


export default React.memo(IndicadoresFinancieros);
