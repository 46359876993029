import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { goToUrl } from '../../helpers/routing';

const Item = (props) => {
  const {
    name, logo, href, active, history
  } = props;

  const goTo = (evt) => {
    evt.preventDefault();
    if (active) {
      goToUrl(history, href);
    }
  };

  return (
    <div className="module-secundary sm-margin-bottom-30" style={{ display: 'block' }}>
      <a
        className={`webapp-btn${active ? '' : '-disabled disabled'}`}
        disabled={!active}
        style={{ border: 'none', height: '150px' }}
        href={href}
        onClick={goTo}
      >
        <img src={logo} style={{ height: '70px' }} alt="" />
        <p>{name}</p>
      </a>
    </div>

  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default React.memo(withRouter(Item));
