import { LOADING } from '../../helpers/statusStates';
import { TABLA } from '../../helpers/mermaGlobalTypes';

import { updateStatus } from './status';

const SET_MERMA_GLOBAL = 'SET_MERMA_GLOBAL';
const RESET_MERMA_GLOBAL = 'RESET_MERMA_GLOBAL';
export const RESET_GLOBAL = 'RESET_GLOBAL';

/*
  Podemos tener acumuladas varias mermas por
  cada grafica que tenemos dentro de nuestra aplicacion.

*/

const initialState = {
  [TABLA]: {
    merma: {}
  },
  GRAFICA_DEFECTOS: {
    merma: {}
  }
};

export function setMermaGlobal(mermaGlobalType, merma) {
  return {
    type: SET_MERMA_GLOBAL,
    payload: {
      [mermaGlobalType]: { merma }
    }
  };
}

export const resetMermaGlobal = mermaGlobalType => (dispatch) => {
  dispatch({
    type: RESET_MERMA_GLOBAL,
    payload: {
      [mermaGlobalType]: { }
    }
  });

  dispatch(updateStatus({ mermaGlobalType: LOADING }));
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_GLOBAL: {
      return { ...initialState };
    }
    case SET_MERMA_GLOBAL: {
      return { ...state, ...action.payload };
    }

    case RESET_MERMA_GLOBAL: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}
