export const actionTypes = {
  SetFechas: '[Filter] Fechas',
  SetFinca: '[Filter] Finca',
  SetYear: '[Filter] Year',
  SetFilters: '[Filter] All',
};

const initialState = {
  start_date: undefined,
  end_date: undefined,
  finca: undefined,
  year: undefined
};


export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetFechas:
      return {
        ...state,
        start_date: action.payload.startDate,
        end_date: action.payload.endDate
      };

    case actionTypes.SetFinca:
      return {
        ...state,
        finca: action.payload.finca
      };

    case actionTypes.SetYear:
      return {
        ...state,
        year: action.payload.year
      };

    case actionTypes.SetFilters:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};


export const actions = {
  setFilters: payload => ({ type: actionTypes.SetFilters, payload }),
  setYear: year => ({ type: actionTypes.SetYear, payload: { year } }),
  setFinca: finca => ({ type: actionTypes.SetFinca, payload: { finca } }),
  setFechas: ({startDate, endDate}) => ({ type: actionTypes.SetFechas, payload: { startDate, endDate } }),
};
