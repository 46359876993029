const SET_THRESHOLD = 'SET_THRESHOLD';

const initialState = {
  merma: 10, // valor default
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THRESHOLD: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
